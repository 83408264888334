import React, { useEffect, useRef, memo, useState } from "react";
import { styled } from "styled-components";
import { Space, Tooltip } from "antd";
import { Minus, Plus } from "@phosphor-icons/react";
import {
  AppButton,
  CardWrapper,
  HeaderTitle,
  Item,
  SectionLayout,
} from "components";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div``;

const TradingViewWidget = () => {
  const container = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const scriptId = "tradingview-widget-script";

    // Check if the script already exists
    if (document.getElementById(scriptId)) {
      return;
    }

    const script = document.createElement("script");
    script.id = scriptId;
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
      {
        "width": "100%",
        "height": "510",
        "autosize": true,
        "symbol": "NASDAQ:AAPL",
        "interval": "D",
        "timezone": "Etc/UTC",
        "theme": "dark",
        "style": "1",
        "locale": "en",
        "allow_symbol_change": true,
        "calendar": false,
        "support_host": "https://www.tradingview.com"
      }`;

    if (container.current) {
      container.current.appendChild(script);
    }
  }, []);

  return (
    <Wrapper className="tradingview-widget-container" ref={container}></Wrapper>
  );
};

const View = memo(TradingViewWidget);

const TradingChartCardWrapper = styled(CardWrapper)`
  margin-top: 0.5rem !important;
  min-height: 0;
`;

const TradingChartHeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    text-align: left !important;
    width: auto !important;
  }
  width: 100%;
  margin: 0 0.5rem;
`;

const TradingChartItem = styled(Item)`
  padding-inline: 0px !important;
`;

export const TradingView = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();

  return (
    <SectionLayout
      header={
        <TradingChartHeaderTitle>
          <HeaderTitle className="text-center me-2">
            {t("trading_view.title")}
          </HeaderTitle>
          <Space>
            <Tooltip
              title={
                isExpanded
                  ? t("common.collapse")
                  : t("common.expand")
              }
            >
              <AppButton
                onClick={() => setIsExpanded(!isExpanded)}
                size="small"
                type="primary"
                shape="circle"
                icon={isExpanded ? <Minus /> : <Plus />}
              />
            </Tooltip>
          </Space>
        </TradingChartHeaderTitle>
      }
      content={isExpanded ? <View /> : ""}
    />
  );
};
