import { DotsSixVertical, Lock } from "@phosphor-icons/react";
import { AppTag, CustomTable } from "components";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";

export const PositionListTable = styled(CustomTable)`
  overflow: scroll;
  overflow: scroll;
  height: calc(100vh - 230px);
`;

export const PositionsTable = () => {
  const { t } = useTranslation();

  const positionColumn = [
    {
      title: "",
      dataIndex: "lm",
      key: "lm",
      render: () => (
        <div className="d-flex align-items-center gap-2">
          <DotsSixVertical size={16} />
          <Lock size={16} />
        </div>
      ),
    },
    {
      title: t("mtm.positions_hedges_common_fields.symbol"),
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: t("mtm.positions_hedges_common_fields.avg_price"),
      dataIndex: "average_price",
      key: "average_price",
    },
    {
      title: t("mtm.positions_hedges_common_fields.qty"),
      dataIndex: "qty",
      key: "qty",
      className: "center",
    },
    {
      title: t("mtm.positions_hedges_common_fields.stop_loss"),
      dataIndex: "sl",
      key: "sl",
      className: "center",
      render: (text: string) => (
        <div className="d-flex justify-content-end">
          <p className="me-2">{text}</p>
          <AppTag color="green">M</AppTag>
          <AppTag color="green">X</AppTag>
        </div>
      ),
    },
    {
      title: t("mtm.positions_hedges_common_fields.target"),
      dataIndex: "target",
      key: "target",
      className: "center",
      render: (text: string) => (
        <div className="d-flex justify-content-end">
          <p className="me-2">{text}</p>
          <AppTag color="green">M</AppTag>
          <AppTag color="green">X</AppTag>
        </div>
      ),
    },
    {
      title: t("mtm.positions_hedges_common_fields.position"),
      dataIndex: "position",
      key: "position",
      className: "center",
      render: (text: string) => (
        <div className="d-flex">
          <AppTag color={text === "Long" ? "green" : "volcano"}>
            {text === "Long" ? "L" : "S"}
          </AppTag>
        </div>
      ),
    },
    {
      title: t("mtm.positions_hedges_common_fields.ltp"),
      dataIndex: "ltp",
      key: "ltp",
      className: "center",
    },
    {
      title: t("mtm.positions_hedges_common_fields.pnl"),
      dataIndex: "pl",
      key: "pl",
      className: "center",
    },
    {
      title: t("mtm.positions_hedges_common_fields.action"),
      dataIndex: "action",
      key: "action",
      className: "center",
      render: (text: string) => (
        <div className="d-flex">
          <AppTag color="green">W</AppTag>
          <AppTag color="green">P</AppTag>
          <AppTag color="green">T</AppTag>
          <AppTag color="green">X</AppTag>
        </div>
      ),
    },
  ];

  const positionData = [
    {
      key: "1",
      lm: ["A", "B"],
      symbol: "AAPL",
      average_price: 140.5,
      qty: 50,
      sl: 135.0,
      target: 150.0,
      position: "Long",
      ltp: 145.0,
      pl: "+$225.00",
      action: "Sell",
    },
    {
      key: "2",
      lm: ["L", "M"],
      symbol: "GOOG",
      average_price: 2800.75,
      qty: 50,
      sl: 2750.0,
      target: 3000.0,
      position: "Short",
      ltp: 2785.25,
      pl: "-$500.00",
      action: "Buy",
    },
    {
      key: "3",
      lm: ["N", "O"],
      symbol: "AAPL",
      average_price: 135.5,
      qty: 100,
      sl: 130.0,
      target: 150.0,
      position: "Long",
      ltp: 138.75,
      pl: "+$325.00",
      action: "Sell",
    },
    {
      key: "4",
      lm: ["P", "Q"],
      symbol: "TSLA",
      average_price: 650.0,
      qty: 20,
      sl: 620.0,
      target: 700.0,
      position: "Long",
      ltp: 675.0,
      pl: "+$500.00",
      action: "Sell",
    },
    {
      key: "5",
      lm: ["R", "S"],
      symbol: "AMZN",
      average_price: 3200.5,
      qty: 15,
      sl: 3100.0,
      target: 3500.0,
      position: "Short",
      ltp: 3150.75,
      pl: "-$750.00",
      action: "Buy",
    },
    {
      key: "6",
      lm: ["T", "U"],
      symbol: "MSFT",
      average_price: 250.0,
      qty: 40,
      sl: 240.0,
      target: 270.0,
      position: "Long",
      ltp: 260.0,
      pl: "+$400.00",
      action: "Sell",
    },
    {
      key: "7",
      lm: ["V", "W"],
      symbol: "NFLX",
      average_price: 500.0,
      qty: 10,
      sl: 480.0,
      target: 550.0,
      position: "Long",
      ltp: 525.0,
      pl: "+$250.00",
      action: "Sell",
    },
    {
      key: "8",
      lm: ["X", "Y"],
      symbol: "FB",
      average_price: 330.0,
      qty: 25,
      sl: 320.0,
      target: 360.0,
      position: "Short",
      ltp: 335.0,
      pl: "-$125.00",
      action: "Buy",
    },
    {
      key: "9",
      lm: ["Z", "A1"],
      symbol: "NVDA",
      average_price: 700.0,
      qty: 15,
      sl: 680.0,
      target: 750.0,
      position: "Long",
      ltp: 725.0,
      pl: "+$375.00",
      action: "Sell",
    },
    {
      key: "10",
      lm: ["B1", "C1"],
      symbol: "DIS",
      average_price: 180.0,
      qty: 30,
      sl: 170.0,
      target: 200.0,
      position: "Long",
      ltp: 190.0,
      pl: "+$300.00",
      action: "Sell",
    },
    {
      key: "11",
      lm: ["D1", "E1"],
      symbol: "BABA",
      average_price: 220.0,
      qty: 60,
      sl: 210.0,
      target: 250.0,
      position: "Short",
      ltp: 215.0,
      pl: "-$300.00",
      action: "Buy",
    },
    {
      key: "12",
      lm: ["F1", "G1"],
      symbol: "NFLX",
      average_price: 510.0,
      qty: 20,
      sl: 495.0,
      target: 550.0,
      position: "Long",
      ltp: 520.0,
      pl: "+$200.00",
      action: "Sell",
    },
    {
      key: "13",
      lm: ["H1", "I1"],
      symbol: "AMZN",
      average_price: 3100.0,
      qty: 5,
      sl: 3000.0,
      target: 3200.0,
      position: "Long",
      ltp: 3150.0,
      pl: "+$250.00",
      action: "Sell",
    },
    {
      key: "14",
      lm: ["J1", "K1"],
      symbol: "GOOG",
      average_price: 2700.0,
      qty: 10,
      sl: 2600.0,
      target: 2900.0,
      position: "Long",
      ltp: 2750.0,
      pl: "+$500.00",
      action: "Sell",
    },
    {
      key: "15",
      lm: ["L1", "M1"],
      symbol: "AAPL",
      average_price: 130.0,
      qty: 70,
      sl: 125.0,
      target: 140.0,
      position: "Long",
      ltp: 135.0,
      pl: "+$350.00",
      action: "Sell",
    },
    {
      key: "16",
      lm: ["N1", "O1"],
      symbol: "TSLA",
      average_price: 700.0,
      qty: 10,
      sl: 680.0,
      target: 750.0,
      position: "Long",
      ltp: 710.0,
      pl: "+$100.00",
      action: "Sell",
    },
    {
      key: "17",
      lm: ["P1", "Q1"],
      symbol: "MSFT",
      average_price: 245.0,
      qty: 30,
      sl: 235.0,
      target: 255.0,
      position: "Long",
      ltp: 250.0,
      pl: "+$150.00",
      action: "Sell",
    },
    {
      key: "18",
      lm: ["R1", "S1"],
      symbol: "NVDA",
      average_price: 680.0,
      qty: 25,
      sl: 660.0,
      target: 700.0,
      position: "Long",
      ltp: 690.0,
      pl: "+$250.00",
      action: "Sell",
    },
    {
      key: "19",
      lm: ["T1", "U1"],
      symbol: "FB",
      average_price: 340.0,
      qty: 20,
      sl: 330.0,
      target: 360.0,
      position: "Short",
      ltp: 345.0,
      pl: "-$100.00",
      action: "Buy",
    },
    {
      key: "20",
      lm: ["V1", "W1"],
      symbol: "DIS",
      average_price: 185.0,
      qty: 40,
      sl: 175.0,
      target: 195.0,
      position: "Long",
      ltp: 190.0,
      pl: "+$200.00",
      action: "Sell",
    },
    {
      key: "21",
      lm: ["X1", "Y1"],
      symbol: "AAPL",
      average_price: 120.0,
      qty: 50,
      sl: 115.0,
      target: 125.0,
      position: "Long",
      ltp: 122.5,
      pl: "+$125.00",
      action: "Sell",
    },
    {
      key: "22",
      lm: ["Z1", "A2"],
      symbol: "GOOG",
      average_price: 2750.0,
      qty: 15,
      sl: 2650.0,
      target: 2850.0,
      position: "Long",
      ltp: 2800.0,
      pl: "+$750.00",
      action: "Sell",
    },
    {
      key: "23",
      lm: ["B2", "C2"],
      symbol: "TSLA",
      average_price: 690.0,
      qty: 10,
      sl: 670.0,
      target: 720.0,
      position: "Long",
      ltp: 700.0,
      pl: "+$100.00",
      action: "Sell",
    },
    {
      key: "24",
      lm: ["D2", "E2"],
      symbol: "AMZN",
      average_price: 3150.0,
      qty: 20,
      sl: 3050.0,
      target: 3250.0,
      position: "Long",
      ltp: 3200.0,
      pl: "+$1000.00",
      action: "Sell",
    },
    {
      key: "25",
      lm: ["F2", "G2"],
      symbol: "MSFT",
      average_price: 255.0,
      qty: 30,
      sl: 245.0,
      target: 265.0,
      position: "Long",
      ltp: 260.0,
      pl: "+$150.00",
      action: "Sell",
    },
    {
      key: "26",
      lm: ["H2", "I2"],
      symbol: "NFLX",
      average_price: 495.0,
      qty: 20,
      sl: 480.0,
      target: 510.0,
      position: "Long",
      ltp: 500.0,
      pl: "+$100.00",
      action: "Sell",
    },
    {
      key: "27",
      lm: ["J2", "K2"],
      symbol: "FB",
      average_price: 345.0,
      qty: 15,
      sl: 335.0,
      target: 355.0,
      position: "Short",
      ltp: 350.0,
      pl: "-$75.00",
      action: "Buy",
    },
    {
      key: "28",
      lm: ["L2", "M2"],
      symbol: "NVDA",
      average_price: 720.0,
      qty: 10,
      sl: 700.0,
      target: 740.0,
      position: "Long",
      ltp: 730.0,
      pl: "+$100.00",
      action: "Sell",
    },
    {
      key: "29",
      lm: ["N2", "O2"],
      symbol: "DIS",
      average_price: 190.0,
      qty: 25,
      sl: 180.0,
      target: 200.0,
      position: "Long",
      ltp: 195.0,
      pl: "+$125.00",
      action: "Sell",
    },
    {
      key: "30",
      lm: ["P2", "Q2"],
      symbol: "AAPL",
      average_price: 125.0,
      qty: 60,
      sl: 120.0,
      target: 130.0,
      position: "Long",
      ltp: 127.5,
      pl: "+$150.00",
      action: "Sell",
    },
    {
      key: "31",
      lm: ["R2", "S2"],
      symbol: "GOOG",
      average_price: 2850.0,
      qty: 12,
      sl: 2750.0,
      target: 2950.0,
      position: "Long",
      ltp: 2900.0,
      pl: "+$600.00",
      action: "Sell",
    },
    {
      key: "32",
      lm: ["T2", "U2"],
      symbol: "TSLA",
      average_price: 680.0,
      qty: 18,
      sl: 660.0,
      target: 700.0,
      position: "Long",
      ltp: 690.0,
      pl: "+$180.00",
      action: "Sell",
    },
    {
      key: "33",
      lm: ["V2", "W2"],
      symbol: "AMZN",
      average_price: 3250.0,
      qty: 8,
      sl: 3150.0,
      target: 3350.0,
      position: "Long",
      ltp: 3300.0,
      pl: "+$400.00",
      action: "Sell",
    },
    {
      key: "34",
      lm: ["X2", "Y2"],
      symbol: "MSFT",
      average_price: 250.0,
      qty: 20,
      sl: 240.0,
      target: 260.0,
      position: "Long",
      ltp: 255.0,
      pl: "+$100.00",
      action: "Sell",
    },
    {
      key: "35",
      lm: ["Z2", "A3"],
      symbol: "NFLX",
      average_price: 500.0,
      qty: 30,
      sl: 490.0,
      target: 520.0,
      position: "Long",
      ltp: 510.0,
      pl: "+$300.00",
      action: "Sell",
    },
    {
      key: "36",
      lm: ["B3", "C3"],
      symbol: "FB",
      average_price: 350.0,
      qty: 10,
      sl: 340.0,
      target: 360.0,
      position: "Short",
      ltp: 355.0,
      pl: "-$50.00",
      action: "Buy",
    },
    {
      key: "37",
      lm: ["D3", "E3"],
      symbol: "NVDA",
      average_price: 710.0,
      qty: 25,
      sl: 690.0,
      target: 730.0,
      position: "Long",
      ltp: 720.0,
      pl: "+$250.00",
      action: "Sell",
    },
    {
      key: "38",
      lm: ["F3", "G3"],
      symbol: "DIS",
      average_price: 195.0,
      qty: 35,
      sl: 185.0,
      target: 205.0,
      position: "Long",
      ltp: 200.0,
      pl: "+$175.00",
      action: "Sell",
    },
    {
      key: "39",
      lm: ["H3", "I3"],
      symbol: "AAPL",
      average_price: 115.0,
      qty: 45,
      sl: 110.0,
      target: 120.0,
      position: "Long",
      ltp: 117.5,
      pl: "+$112.50",
      action: "Sell",
    },
    {
      key: "40",
      lm: ["J3", "K3"],
      symbol: "GOOG",
      average_price: 2900.0,
      qty: 9,
      sl: 2800.0,
      target: 3000.0,
      position: "Long",
      ltp: 2950.0,
      pl: "+$450.00",
      action: "Sell",
    },
    {
      key: "41",
      lm: ["L3", "M3"],
      symbol: "TSLA",
      average_price: 675.0,
      qty: 12,
      sl: 655.0,
      target: 695.0,
      position: "Long",
      ltp: 685.0,
      pl: "+$120.00",
      action: "Sell",
    },
    {
      key: "42",
      lm: ["N3", "O3"],
      symbol: "AMZN",
      average_price: 3300.0,
      qty: 15,
      sl: 3200.0,
      target: 3400.0,
      position: "Long",
      ltp: 3350.0,
      pl: "+$750.00",
      action: "Sell",
    },
    {
      key: "43",
      lm: ["P3", "Q3"],
      symbol: "MSFT",
      average_price: 240.0,
      qty: 25,
      sl: 230.0,
      target: 250.0,
      position: "Long",
      ltp: 245.0,
      pl: "+$125.00",
      action: "Sell",
    },
    {
      key: "44",
      lm: ["R3", "S3"],
      symbol: "NFLX",
      average_price: 505.0,
      qty: 20,
      sl: 495.0,
      target: 515.0,
      position: "Long",
      ltp: 510.0,
      pl: "+$100.00",
      action: "Sell",
    },
    {
      key: "45",
      lm: ["T3", "U3"],
      symbol: "FB",
      average_price: 355.0,
      qty: 25,
      sl: 345.0,
      target: 365.0,
      position: "Short",
      ltp: 360.0,
      pl: "-$125.00",
      action: "Buy",
    },
    {
      key: "46",
      lm: ["V3", "W3"],
      symbol: "NVDA",
      average_price: 705.0,
      qty: 18,
      sl: 685.0,
      target: 725.0,
      position: "Long",
      ltp: 715.0,
      pl: "+$180.00",
      action: "Sell",
    },
    {
      key: "47",
      lm: ["X3", "Y3"],
      symbol: "DIS",
      average_price: 200.0,
      qty: 20,
      sl: 190.0,
      target: 210.0,
      position: "Long",
      ltp: 205.0,
      pl: "+$100.00",
      action: "Sell",
    },
    {
      key: "48",
      lm: ["Z3", "A4"],
      symbol: "AAPL",
      average_price: 110.0,
      qty: 35,
      sl: 105.0,
      target: 115.0,
      position: "Long",
      ltp: 112.5,
      pl: "+$87.50",
      action: "Sell",
    },
    {
      key: "49",
      lm: ["B4", "C4"],
      symbol: "GOOG",
      average_price: 2950.0,
      qty: 5,
      sl: 2850.0,
      target: 3050.0,
      position: "Long",
      ltp: 3000.0,
      pl: "+$250.00",
      action: "Sell",
    },
    {
      key: "50",
      lm: ["D4", "E4"],
      symbol: "TSLA",
      average_price: 660.0,
      qty: 20,
      sl: 640.0,
      target: 680.0,
      position: "Long",
      ltp: 670.0,
      pl: "+$200.00",
      action: "Sell",
    },
  ];

  return (
    <PositionListTable
      pagination={false}
      columns={positionColumn}
      dataSource={positionData}
    />
  );
};
