import { ButtonProps, Dropdown, MenuProps, Radio, Space, Switch } from "antd";
import { styled } from "styled-components";
import { Form as AntForm } from "antd";
import { useState } from "react";
import {
  AppButton,
  AppInput,
  AppRadioGroup,
  CardWrapper,
  Item,
  QuickSettingItem,
} from "components";
import { IMenuItem } from "models";
import { useTranslation } from "react-i18next";

export const BoxWrapper = styled(CardWrapper)`
  margin-top: 0.5rem !important;
  .ant-list-header {
    padding-block: 0.5rem !important;
    padding-inline: 0.5rem !important;
  }
`;

export const BoxItem = styled(Item)`
  padding: 0.5rem !important;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.primary} !important;
  padding: 0.25rem;
  gap: 0;
`;

export const Content = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  box-sizing: border-box;
`;

export const HeaderDropdown = styled(AppButton)`
  min-width: 100px;
`;

export const HeaderButtons = styled.div`
  display: flex;
  margin-top: 0.25rem;
  button {
    flex: 1;
    padding: 0px 10px !important;
  }
`;

export const Buy = styled(AppButton)`
  && {
    background: ${({ theme }) => theme.darkGreen};
    border-color: ${({ theme }) => theme.darkGreen};
    color: ${({ theme }) => theme.white};
    min-width: 120px;
  }
`;

export const Sell = styled(AppButton)`
  && {
    background: ${({ theme }) => theme.red};
    border-color: ${({ theme }) => theme.red};
    color: ${({ theme }) => theme.white};
    min-width: 120px;
  }
`;

export const Cancel = styled(AppButton)`
  && {
    background: ${({ theme }) => theme.blue};
    border-color: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
    min-width: 120px;
  }
`;

export const Form = styled(AntForm)`
  display: flex;
  width: 100%;
`;

export const FormItem = styled(AntForm.Item)`
  margin-inline-end: 0px !important;
  flex: 1 !important;
`;

export const StyledAppInput = styled(AppInput)`
  flex: 0 1 auto;
  min-width: 60px;
`;

export const BlueButton = styled(AppButton)<StyledButtonProps>`
  && {
    background: ${({ theme }) => theme.blue} !important;
    border-color: ${({ theme }) => theme.blue} !important;
    color: ${({ theme }) => theme.white};
    &:hover {
      color: ${({ theme }) => theme.black} !important;
    }
  }
  margin-top: 10px;
`;

export const StyledAppButton = styled(AppButton)`
  max-width: 40px;
  &.green {
    background-color: ${({ theme }) => theme.darkGreen};
  }

  &.red {
    background-color: ${({ theme }) => theme.red};
  }
  color: ${({ theme }) => theme.white} !important;
`;

const HedgeTitle = styled.h3`
  font-size: 1rem !important;
  margin-top: 0.5rem !important;
`;

const CePeWrapper = styled.div`
  display: flex;
  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CePeLtp = styled.p`
  text-align: center;
`;

const HedgeSectionWrapper = styled.div`
  margin-top: 0.9rem;
  position: relative;
  padding-bottom: 0.9rem;
  border-bottom: 1px solid #e3e3e3;
  &:last-child {
    border-bottom: none;
    padding-bottom: 0px;
  }
`;

const HedgeSectionSwitch = styled(Switch)`
  position: absolute !important;
  top: 0rem;
  right: 0rem;
`;

export const Spacer = styled.div`
  margin-top: 0.5rem;
`;

export const BuyCe = styled(Buy)`
  min-width: 20px !important;
`;

export const BuyPe = styled(Buy)`
  min-width: 20px !important;
`;

export const SellCe = styled(Sell)`
  min-width: 20px !important;
`;

export const SellPe = styled(Sell)`
  min-width: 20px !important;
`;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  button {
    flex: 1;
  }
  div {
    flex: 1;
  }
`;

const ItemWrapperSecond = styled.div`
  display: flex;
  width: 100%;
  button {
    flex: 1;
  }
  div {
    flex: 3;
  }
`;

const BuySellButtonWrapper = styled.div`
  display: flex;
  button {
    max-width: 80px !important;
    min-width: 80px !important;
  }
`;

export const BuySell = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  .buy {
    border-right: 1px dashed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding-right: 0.5rem;
  }
  .sell {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding-left: 0.5rem;
  }
`;

const PercentageGreenStyled = styled(Radio.Button)`
  background-color: ${({ theme }) => theme.darkGreen} !important;
  color: ${({ theme }) => theme.white} !important;
  border: 0.0625rem solid ${({ theme }) => theme.border} !important;
  padding: 0px 0.5rem !important;
  &:hover {
    background-color: ${({ theme }) => theme.lightGreen} !important;
    color: ${({ theme }) => theme.black} !important;
  }
  & .ant-radio-button-wrapper {
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }
  & .ant-radio-button-wrapper:first-child {
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }
  & .ant-radio-button-wrapper:last-child {
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }
  &.ant-radio-button-wrapper-checked {
    background-color: ${({ theme }) => theme.lightGreen} !important;
    color: ${({ theme }) => theme.black} !important;
  }
`;

const PercentageRedStyled = styled(Radio.Button)`
  background-color: ${({ theme }) => theme.red} !important;
  color: ${({ theme }) => theme.white} !important;
  border: 0.0625rem solid ${({ theme }) => theme.border} !important;
  padding: 0px 0.5rem !important;
  &:hover {
    background-color: ${({ theme }) => theme.darkRed} !important;
  }
  & .ant-radio-button-wrapper {
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }
  & .ant-radio-button-wrapper:first-child {
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }
  & .ant-radio-button-wrapper:last-child {
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }

  &.ant-radio-button-wrapper-checked {
    background-color: ${({ theme }) => theme.lightRed} !important;
    color: ${({ theme }) => theme.black} !important;
  }
`;

const useTranslatedMenuItems = () => {
  const { t } = useTranslation();

  const exchangeItems: MenuProps["items"] = [
    {
      key: "nse",
      label: t("terminal.common_options.exchange_options.nse"),
    },
    {
      key: "bse",
      label: t("terminal.common_options.exchange_options.bse"),
    },
    {
      key: "mcx",
      label: t("terminal.common_options.exchange_options.mcx"),
    },
  ];

  const segmentItems: MenuProps["items"] = [
    {
      key: "equity",
      label: t("terminal.common_options.segment_options.equity"),
    },
    {
      key: "futures",
      label: t("terminal.common_options.segment_options.futures"),
    },
    {
      key: "options",
      label: t("terminal.common_options.segment_options.options"),
    },
  ];

  const symbolItems: MenuProps["items"] = [
    {
      key: "nifty",
      label: t("terminal.common_options.symbol_options.nifty"),
    },
    {
      key: "bank-nifty",
      label: t("terminal.common_options.symbol_options.bank_nifty"),
    },
    {
      key: "finnifty",
      label: t("terminal.common_options.symbol_options.finnifty"),
    },
    {
      key: "midcpnifty",
      label: t("terminal.common_options.symbol_options.midcpnifty"),
    },
    {
      key: "sensex",
      label: t("terminal.common_options.symbol_options.sensex"),
    },
  ];

  const expiryItems: MenuProps["items"] = [
    {
      key: "nifty1",
      label: "10th May",
    },
    {
      key: "nifty2",
      label: "17th May",
    },
    {
      key: "nifty3",
      label: "24th May",
    },
    {
      key: "nifty4",
      label: "30th May",
    },
  ];

  const productItems: MenuProps["items"] = [
    {
      key: "intraday",
      label: t("terminal.common_options.product_options.intraday"),
    },
    {
      key: "margin",
      label: t("terminal.common_options.product_options.margin"),
    },
  ];

  const callItems: MenuProps["items"] = [
    {
      key: "call1",
      label: "Call 1",
    },
    {
      key: "call2",
      label: "Call 2",
    },
  ];

  const putItems: MenuProps["items"] = [
    {
      key: "put1",
      label: "Put 1",
    },
    {
      key: "put2",
      label: "Put 2",
    },
  ];

  const typeItems: MenuProps["items"] = [
    {
      key: "limit",
      label: t("terminal.common_options.type_options.limit"),
    },
    {
      key: "market",
      label: t("terminal.common_options.type_options.market"),
    },
  ];

  const validityItems: MenuProps["items"] = [
    {
      key: "day",
      label: t("terminal.common_options.validity_options.day"),
    },
    {
      key: "ioc",
      label: t("terminal.common_options.validity_options.ioc"),
    },
  ];

  const CeItems: MenuProps["items"] = [
    {
      key: "1",
      label: "16700 CE",
    },
    {
      key: "2",
      label: "17700 CE",
    },
  ];

  const PeItems: MenuProps["items"] = [
    {
      key: "1",
      label: "16700 PE",
    },
    {
      key: "2",
      label: "17700 PE",
    },
  ];

  const lotItems: MenuProps["items"] = [
    {
      key: 1,
      label: t("terminal.common_options.lots_options.1"),
    },
    {
      key: 2,
      label: t("terminal.common_options.lots_options.2"),
    },
    {
      key: 3,
      label: t("terminal.common_options.lots_options.3"),
    },
    {
      key: 4,
      label: t("terminal.common_options.lots_options.4"),
    },
    {
      key: 5,
      label: t("terminal.common_options.lots_options.5"),
    },
  ];

  const strikeItems: MenuProps["items"] = [
    {
      key: "21000",
      label: "21000",
    },
    {
      key: "21200",
      label: "21200",
    },
    {
      key: "21300",
      label: "21300",
    },
    {
      key: "21400",
      label: "21400",
    },
    {
      key: "21500",
      label: "21500",
    },
    {
      key: "21600",
      label: "21600",
    },
    {
      key: "21700",
      label: "21700",
    },
    {
      key: "21800",
      label: "21800",
    },
    {
      key: "21900",
      label: "21900",
    },
    {
      key: "22000",
      label: "22000",
    },
  ];

  const hedgeItems: MenuProps["items"] = [
    {
      key: "25",
      label: t("terminal.common_options.percentage_options.percent_25"),
    },
    {
      key: "50",
      label: t("terminal.common_options.percentage_options.percent_50"),
    },
    {
      key: "75",
      label: t("terminal.common_options.percentage_options.percent_75"),
    },
    {
      key: "100",
      label: t("terminal.common_options.percentage_options.percent_100"),
    },
  ];

  const HedgeSelectItems: IMenuItem[] = [
    { key: "1", label: "1. Dropdown" },
    { key: "2", label: "2. Dropdown" },
  ];

  const lotMultiplierItems: MenuProps["items"] = [
    {
      key: 1,
      label: t("terminal.common_options.lots_options.1"),
    },
    {
      key: 2,
      label: t("terminal.common_options.lots_options.2"),
    },
    {
      key: 3,
      label: t("terminal.common_options.lots_options.3"),
    },
    {
      key: 4,
      label: t("terminal.common_options.lots_options.4"),
    },
    {
      key: 5,
      label: t("terminal.common_options.lots_options.5"),
    },
    {
      key: 6,
      label: t("terminal.common_options.lots_options.6"),
    },
    {
      key: 7,
      label: t("terminal.common_options.lots_options.7"),
    },
    {
      key: 8,
      label: t("terminal.common_options.lots_options.8"),
    },
    {
      key: 9,
      label: t("terminal.common_options.lots_options.9"),
    },
    {
      key: 10,
      label: t("terminal.common_options.lots_options.10"),
    },
  ];

  return {
    exchangeItems,
    segmentItems,
    symbolItems,
    lotItems,
    PeItems,
    CeItems,
    validityItems,
    typeItems,
    putItems,
    expiryItems,
    callItems,
    productItems,
    HedgeSelectItems,
    hedgeItems,
    strikeItems,
    lotMultiplierItems,
  };
};

export default useTranslatedMenuItems;

export const SlTargetForm = () => {
  const onFinish = (values: any) => {
    console.log("Received values:", values);
  };
  const { t } = useTranslation();

  return (
    <Form layout="inline" onFinish={onFinish}>
      <FormItem name="quantity" rules={[{ max: 5 }]}>
        <AppInput
          placeholder={t("terminal.common_options.placeholder.set_sl")}
          maxLength={5}
        />
      </FormItem>
      <FormItem name="price1" rules={[{ max: 6 }]}>
        <AppInput
          placeholder={t("terminal.common_options.placeholder.target")}
          maxLength={6}
        />
      </FormItem>
      <FormItem name="price2" rules={[{ max: 6 }]}>
        <AppInput
          placeholder={t("terminal.common_options.placeholder.trail_sl")}
          maxLength={6}
        />
      </FormItem>
    </Form>
  );
};

export interface StyledButtonProps extends ButtonProps {
  active?: boolean;
}

export const MarketToggle = () => {
  const { t } = useTranslation();
  return (
    <Space direction="horizontal">
      {t("terminal.common_options.market_toggle.mkt")}
      <Switch defaultChecked size="small" />
      {t("terminal.common_options.market_toggle.ltp")}
    </Space>
  );
};

export const HedgeSection = () => {
  const initialHedges = [
    { id: 1, isVisible: true },
    // { id: 2, isVisible: false },
  ];
  const [hedges, setHedges] = useState(initialHedges);

  const { expiryItems, CeItems, HedgeSelectItems } = useTranslatedMenuItems();

  const toggleHedgesVisibility = (id: number) => {
    setHedges(
      hedges.map((el) =>
        el.id === id ? { ...el, isVisible: !el.isVisible } : el
      )
    );
  };
  const { t } = useTranslation();

  return (
    <>
      <HedgeTitle>{t("terminal.common_options.hedge")}</HedgeTitle>
      {hedges.map((h) => {
        return (
          <HedgeSectionWrapper className="mt-3" key={h.id}>
            <HedgeSectionSwitch
              defaultChecked
              size="small"
              value={h.isVisible}
              onClick={() => toggleHedgesVisibility(h.id)}
            />

            {!h.isVisible && (
              <h3>
                {t("terminal.common_options.hedge")}:{h.id}
              </h3>
            )}

            {h.isVisible && (
              <>
                <div className="d-flex mt-3">
                  <MarketToggle />
                </div>
                <BuySell>
                  <div className="buy">
                    <div className="d-flex justify-content-center">
                      <h3 className="text-center">
                        {t("terminal.common_options.call_strike")}
                      </h3>
                    </div>

                    <ItemWrapper>
                      <Dropdown
                        menu={{ items: expiryItems }}
                        placement="bottomLeft"
                      >
                        <AppButton>
                          {t("terminal.common_options.expiry")}
                        </AppButton>
                      </Dropdown>

                      <Dropdown
                        menu={{ items: CeItems }}
                        placement="bottomLeft"
                      >
                        <AppButton>{t("terminal.common_options.ce")}</AppButton>
                      </Dropdown>
                    </ItemWrapper>

                    <div>
                      <h3>{t("terminal.common_options.call_ltp")}</h3>
                    </div>

                    <BuySellButtonWrapper>
                      <Buy>{t("terminal.common_options.buy_ce")}</Buy>
                      <Sell>{t("terminal.common_options.sell_ce")}</Sell>
                    </BuySellButtonWrapper>
                  </div>
                  <div className="sell">
                    <div className="d-flex justify-content-center">
                      <h3>{t("terminal.common_options.put_strike")}</h3>
                    </div>

                    <ItemWrapperSecond>
                      <Dropdown
                        menu={{ items: CeItems }}
                        placement="bottomLeft"
                      >
                        <AppButton>{t("terminal.common_options.ce")}</AppButton>
                      </Dropdown>

                      <QuickSettingItem
                        type="input-notch-select"
                        buttonText=""
                        placeholder={t("terminal.common_options.quantity")}
                        items={HedgeSelectItems}
                      />
                    </ItemWrapperSecond>

                    <div>
                      <h3>{t("terminal.common_options.put_ltp")}</h3>
                    </div>

                    <BuySellButtonWrapper>
                      <Buy>{t("terminal.common_options.buy_pe")}</Buy>
                      <Sell>{t("terminal.common_options.sell_pe")}</Sell>
                    </BuySellButtonWrapper>
                  </div>
                </BuySell>
              </>
            )}
          </HedgeSectionWrapper>
        );
      })}
    </>
  );
};

export const HedgeSection1 = () => {
  const initialHedges = [
    { id: 1, isVisible: true },
    { id: 2, isVisible: true },
  ];
  const [hedges, setHedges] = useState(initialHedges);

  const toggleHedgesVisibility = (id: number) => {
    setHedges(
      hedges.map((el) =>
        el.id === id ? { ...el, isVisible: !el.isVisible } : el
      )
    );
  };
  const { t } = useTranslation();

  const { expiryItems, strikeItems, hedgeItems, lotItems } =
    useTranslatedMenuItems();

  return (
    <div className="mt-3">
      <HedgeTitle>{t("terminal.common_options.hedge")}</HedgeTitle>

      {hedges.map((h) => {
        return (
          <HedgeSectionWrapper key={h.id}>
            <HedgeSectionSwitch
              defaultChecked
              size="small"
              onClick={() => toggleHedgesVisibility(h.id)}
            />

            {!h.isVisible && (
              <h3>
                {t("terminal.common_options.hedge")}: {h.id}
              </h3>
            )}

            {h.isVisible && (
              <>
                <div className="d-flex mt-3">
                  <MarketToggle />
                </div>

                <Spacer>
                  <CePeWrapper>
                    <div>
                      <BuyCe>CE</BuyCe>
                    </div>
                    <div>
                      <CePeLtp>
                        CE <br />
                        LTP
                      </CePeLtp>
                    </div>

                    <div>
                      <Dropdown
                        menu={{ items: strikeItems }}
                        placement="bottomLeft"
                      >
                        <AppButton>
                          {t("terminal.common_options.strike")}
                        </AppButton>
                      </Dropdown>
                    </div>

                    <div>
                      <Dropdown
                        menu={{ items: expiryItems }}
                        placement="bottomLeft"
                      >
                        <AppButton>
                          {t("terminal.common_options.expiry")}
                        </AppButton>
                      </Dropdown>
                    </div>

                    <div>
                      <Dropdown
                        menu={{ items: strikeItems }}
                        placement="bottomLeft"
                      >
                        <AppButton>
                          {t("terminal.common_options.strike")}
                        </AppButton>
                      </Dropdown>
                    </div>

                    <div>
                      <CePeLtp>
                        PE <br />
                        LTP
                      </CePeLtp>
                    </div>
                    <div>
                      <BuyPe>PE</BuyPe>
                    </div>
                  </CePeWrapper>
                </Spacer>

                <Spacer>
                  <CePeWrapper>
                    <div>
                      <SellCe>CE</SellCe>
                    </div>
                    <div></div>
                    <div>
                      <Dropdown
                        menu={{ items: hedgeItems }}
                        placement="bottomLeft"
                      >
                        <AppButton>
                          {t("terminal.common_options.hedge")}
                        </AppButton>
                      </Dropdown>
                    </div>
                    <div>
                      <Dropdown
                        menu={{ items: lotItems }}
                        placement="bottomLeft"
                      >
                        <AppButton>
                          {t("terminal.common_options.lots_options.lots")}
                        </AppButton>
                      </Dropdown>
                    </div>
                    <div>
                      <AppButton>
                        {t("terminal.common_options.exit_hedge")}
                      </AppButton>
                    </div>
                    <div></div>
                    <div>
                      <SellPe>PE</SellPe>
                    </div>
                  </CePeWrapper>
                </Spacer>
              </>
            )}
          </HedgeSectionWrapper>
        );
      })}
    </div>
  );
};

export const PercentageButtonsBuy = () => {
  const { t } = useTranslation();

  return (
    <>
      <AppRadioGroup>
        <PercentageGreenStyled value="25">
          {t("terminal.common_options.percentage_options.percent_25")}
        </PercentageGreenStyled>
        <PercentageGreenStyled value="50">
          {t("terminal.common_options.percentage_options.percent_50")}
        </PercentageGreenStyled>
        <PercentageGreenStyled value="75">
          {t("terminal.common_options.percentage_options.percent_75")}
        </PercentageGreenStyled>
        <PercentageGreenStyled value="100">
          {t("terminal.common_options.percentage_options.percent_100")}
        </PercentageGreenStyled>
      </AppRadioGroup>
    </>
  );
};

export const PercentageButtonsSell = () => {
  const { t } = useTranslation();

  return (
    <>
      <AppRadioGroup>
        <PercentageRedStyled value="25">
          {t("terminal.common_options.percentage_options.percent_25")}
        </PercentageRedStyled>
        <PercentageRedStyled value="50">
          {t("terminal.common_options.percentage_options.percent_50")}
        </PercentageRedStyled>
        <PercentageRedStyled value="75">
          {t("terminal.common_options.percentage_options.percent_75")}
        </PercentageRedStyled>
        <PercentageRedStyled value="100">
          {t("terminal.common_options.percentage_options.percent_100")}
        </PercentageRedStyled>
      </AppRadioGroup>
    </>
  );
};
