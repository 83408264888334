import { MtmComponent, Overview } from "page-components/dashboard";
import { QuickSettingComponent } from "page-components/dashboard/quick-setting";
import { Terminals } from "page-components/dashboard/terminals";
import { TradingView } from "page-components/dashboard/trading-view";
import { WatchListComponent } from "page-components/dashboard/watch-list";
import { styled } from "styled-components";

const LayoutPadding = styled.div`
  padding: 0 0.5rem;
`;

const Container = styled.div`
  display: flex;
  gap: 8px;
`;

const Box = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
`;

const Box1 = styled(Box)`
  flex-basis: 70%;
  max-width: 70%;
  height: calc(100vh - 230px);
  @media (max-width: 1330px) {
    flex-basis: 65%;
    max-width: 65%;
  }
`;

const Box2 = styled(Box)`
  flex-basis: 30%;
  max-width: 30%;
  flex-shrink: 0;
  max-height: calc(100vh - 58px);
  height: calc(100vh - 58px);
  overflow: scroll;
  @media (max-width: 1330px) {
    flex-basis: 35%;
    max-width: 35%;
  }
`;
export const DashboardScreen = () => {
  return (
    <>
      <LayoutPadding>
        <Container>
          <Box1>
            <QuickSettingComponent />
            <TradingView />
            <MtmComponent />
          </Box1>

          <Box2>
            <Overview />
            <WatchListComponent />
            <Terminals />
          </Box2>
        </Container>
      </LayoutPadding>
    </>
  );
}
