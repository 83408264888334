import { Item, ItemWrapper } from "components";
import { useTranslation } from "react-i18next";

export const PlStatComponent = () => {
  const { t } = useTranslation();

  return (
    <ItemWrapper>
      <Item>
        {t("overview.pl_stat_component.gross_pnl")}
        <span>Rs. 54,000</span>
      </Item>
      <Item>
        {t("overview.pl_stat_component.roi")}
        <span>25%</span>
      </Item>
      <Item>
        {t("overview.pl_stat_component.nett_pnl")}
        <span>Rs. 50,000</span>
      </Item>

      <Item>
        {t("overview.pl_stat_component.avg_winners")}
        <span>Rs. 50,000</span>
      </Item>

      <Item>
        {t("overview.pl_stat_component.charges")}
        <span>Rs. 4,000</span>
      </Item>

      <Item>
        {t("overview.pl_stat_component.avg_losers")}
        <span>Rs. 50,000</span>
      </Item>
    </ItemWrapper>
  );
};
