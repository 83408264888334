import {
  Divider,
  Form,
  Typography,
  Layout as AntdLayout,
  Image,
  Menu,
} from "antd";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  AppButton,
  AppInput,
  AppPasswordInput,
  AuthBottomLink,
  FormLayouts,
  Wrapper,
} from "components/common";

import { useState } from "react";
import { useUser } from "contexts/user-context";
import { PAGE_ROUTE, USER } from "utilities";
import api from "utilities/services/api";
import { Link, useNavigate } from "react-router-dom";
import { ILogin } from "models";
import { useTranslation } from "react-i18next";

const { Header } = AntdLayout;

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .required("Email is required")
    .default("huqebi@mailinator.com"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .default("Pa$$w0rd!"),
});

const { Title } = Typography;

const HeaderLayout = styled(Header)`
  display: flex;
  justify-content: space-between;
  box-shadow: none;
  height: auto;

  @media screen and (max-width: 1023px) {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
  }

  .header-row {
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
`;

export const LoginScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { login } = useUser();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILogin>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({}) as ILogin,
  });

  const onSubmit = async (formData: ILogin) => {
    setLoading(true);
    try {
      // const response = await api.post(USER.LOGIN, formData);
      login({
        token: "response.token",
      });
      navigate(PAGE_ROUTE.DASHBOARD);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const menuItems = [
    { key: "1", label: <Link to="/pricing">{t("header_menu.pricing")}</Link> },
    {
      key: "2",
      label: (
        <Link to="/term-conditions">
          {t("header_menu.terns_and_condition")}
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link to="/privacy-policy">{t("header_menu.privacy_policy")}</Link>
      ),
    },
    {
      key: "4",
      label: <Link to="/refund-policy">{t("header_menu.refund_policy")}</Link>,
    },
    {
      key: "5",
      label: <Link to="/contact-us">{t("header_menu.contact_us")}</Link>,
    },
  ];

  return (
    <>
      <HeaderLayout>
        <Image
          src="https://placehold.it/100x40"
          alt="logo image"
          width={100}
          height={40}
        />

        <div className="d-flex justify-content-end">
          <Menu theme="dark" mode="horizontal" items={menuItems} />
        </div>
      </HeaderLayout>
      <Wrapper>
        <FormLayouts>
          <Title>{t("login_page.welcome")}</Title>
          <Divider />
          <Form
            name="wrap"
            labelCol={{ flex: "150px" }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
            style={{ maxWidth: 600 }}
            onFinish={handleSubmit(onSubmit)}
          >
            <Form.Item
              label={t("login_page.email")}
              validateStatus={errors.email ? "error" : ""}
              help={errors.email && errors.email.message}
            >
              <Controller
                control={control}
                name="email"
                render={({ field }) => <AppInput {...field} />}
              />
            </Form.Item>

            <Form.Item
              label={t("login_page.password")}
              validateStatus={errors.password ? "error" : ""}
              help={errors.password && errors.password.message}
            >
              <Controller
                control={control}
                name="password"
                render={({ field }) => <AppPasswordInput {...field} />}
              />
            </Form.Item>

            <Form.Item>
              <AppButton type="primary" htmlType="submit" loading={loading}>
                {t("login_page.login")}
              </AppButton>
            </Form.Item>

            <AuthBottomLink className="text-center">
              {t("login_page.dont_have_an_account")}{" "}
              <Link to="/register">{t("login_page.sign_up")}</Link>
            </AuthBottomLink>

            <AuthBottomLink className="text-center mt-4">
              <Link to="/forgot-password">
                {t("login_page.forgot_password")}
              </Link>
            </AuthBottomLink>
          </Form>
        </FormLayouts>
      </Wrapper>
    </>
  );
};
