import { styled } from "styled-components";
import { TabsProps } from "antd";
import { CustomTabs } from "components";
import { EquityFuturesTerminal } from "./terminal/equity-futures";
import { OptionsStrategyTerminal } from "./terminal/options-strategy";
import { OptionsDirectionalTerminal } from "./terminal/options-directional";
import { useTranslation } from "react-i18next";

export const TerminalTabs = styled(CustomTabs)`
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-nav {
    margin: 0px !important;
    display: flex;
    justify-content: space-between;

    .ant-tabs-tab {
      flex: 1;
      text-align: center;
      max-width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const TerminalWrapper = styled.div`
  background-color: ${({ theme }) => theme.white};
  margin-top: 0.5rem;
`;

export const Terminals = () => {
  const { t } = useTranslation();

  const terminalItems: TabsProps["items"] = [
    {
      key: "equity",
      label: t("terminal.options.equity"),
      children: <EquityFuturesTerminal />,
    },
    {
      key: "futures",
      label: t("terminal.options.futures"),
      children: <EquityFuturesTerminal />,
    },
    {
      key: "options-1",
      label: t("terminal.options.options_i"),
      children: <OptionsStrategyTerminal />,
    },
    {
      key: "options-2",
      label: t("terminal.options.options_ii"),
      children: <OptionsDirectionalTerminal />,
    },
  ];

  return (
    <TerminalWrapper>
      <TerminalTabs type="card" defaultActiveKey="1" items={terminalItems} />
    </TerminalWrapper>
  );
};
