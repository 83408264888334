import { useState } from "react";
import { Radio, Space } from "antd";
import type { RadioChangeEvent } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { showSuccessNotification } from "utilities";
import { AppModal, AppRadioGroup, QuickSettingItem } from "components";
import { useTranslation } from "react-i18next";

export const DayLoss = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    showSuccessNotification(`Terminal locked for ${value} mins`);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [value, setValue] = useState(1);
  const [lockPeriod, setLockPeriod] = useState();

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const onChangeLock = (e: RadioChangeEvent) => {
    setValue(3);
    setLockPeriod(e.target.value);
  };

  return (
    <>
      <QuickSettingItem
        placeholder={t("day_target.day_loss_placeholder")}
        type="input-notch"
        notchIcon={<SettingOutlined onClick={() => showModal()} />}
      />

      <AppModal
        title={t("day_target.loss_title")}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="d-flex flex-column">
          <AppRadioGroup onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value={1}>{t("day_target.alert_me")}</Radio>
              <Radio value={2}>{t("day_target.square_off_positions")}</Radio>
              <Radio value={3}>{t("day_target.lock_terminal.title")}</Radio>
            </Space>
          </AppRadioGroup>

          <div className="ms-4 mt-2">
            <AppRadioGroup name="sub-option" onChange={onChangeLock}>
              <Radio.Button value={15}>
                {t("day_target.lock_terminal.15_mins")}
              </Radio.Button>
              <Radio.Button value={30}>
                {t("day_target.lock_terminal.30_mins")}
              </Radio.Button>
              <Radio.Button value={60}>
                {t("day_target.lock_terminal.1_hr")}
              </Radio.Button>
              <Radio.Button value={120}>
                {t("day_target.lock_terminal.2_hrs")}
              </Radio.Button>
              <Radio.Button value={240}>
                {t("day_target.lock_terminal.4_hrs")}
              </Radio.Button>
              <Radio.Button value={0}>
                {t("day_target.lock_terminal.full_day")}
              </Radio.Button>
            </AppRadioGroup>
          </div>
        </div>
      </AppModal>
    </>
  );
};
