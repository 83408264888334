import { Timer } from "@phosphor-icons/react";
import {
  Button,
  ButtonProps,
  Checkbox,
  Divider,
  Dropdown,
  Input,
  List,
  ListProps,
  Modal,
  Radio,
  Select,
  Tag,
  TimePicker,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import styled, { css } from "styled-components";

interface InputProps {
  placeholderalign?: "left" | "center" | "right";
}

export const AppTimePicker = styled(TimePicker)`
  &.ant-picker {
    border-radius: 0 !important;
  }
`;

export const AppRadioGroup = styled(Radio.Group)`
  & .ant-radio-button-wrapper {
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }
  & .ant-radio-button-wrapper:first-child {
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }
  & .ant-radio-button-wrapper:last-child {
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }
`;

export const AppCheckBoxGroup = styled(Checkbox.Group)`
  .ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-radius: ${({ theme }) => theme.borderRadius} !important;
      }
    }
  }
`;

export const AppInput = styled(Input)<InputProps>`
  .ant-input {
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }
  .ant-input-group-addon {
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }
  ::placeholder {
    text-align: ${({ placeholderalign }) => placeholderalign || "left"};
  }
  border-radius: ${({ theme }) => theme.borderRadius} !important;
`;

export const AppPasswordInput = styled(Input.Password)`
  border-radius: ${({ theme }) => theme.borderRadius} !important;  
`;

interface AppButtonProps extends ButtonProps {
  color?: string;
}

export const AppPassword = styled(Input)`
  border-radius: ${({ theme }) => theme.borderRadius} !important;
`;

export const AppButton = styled(Button)<AppButtonProps>`
  border-radius: ${({ theme }) => theme.borderRadius} !important;
`;

export const AppTextArea = styled(TextArea)`
  border-radius: ${({ theme }) => theme.borderRadius} !important;
`;

export const AppDropdown = styled(Dropdown.Button)`
  .ant-dropdown-menu {
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }

  .ant-btn {
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }
`;

export const AppSelect = styled(Select)`
  .ant-select-selector {
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }
`;

export const AppModal = styled(Modal)`
  .ant-modal-content {
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }
  .ant-modal-footer .ant-btn {
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }
`;

export const AppTag = styled(Tag)`
  border-radius: ${({ theme }) => theme.borderRadius} !important;
`;

export const Wrapper = styled.div`
  background: #f12711;
  background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  background: linear-gradient(to right, #f5af19, #f12711);
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormLayouts = styled.div`
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 37.5rem;
  background-color: white;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
`;

export const AuthBottomLink = styled.p`
  font-weight: 500;
  color: #52525b;
`;

export const DividerFixMargin = styled(Divider)`
  margin: 0.9375rem 0;
`;

export const FontBold = css`
  font-family: "robotobold", sans-serif !important;
`;

export const FontBoldItalic = css`
  font-family: "robotomedium_italic", sans-serif !important;
`;

export const FontMedium = css`
  font-family: "robotomedium", sans-serif !important;
`;

export const FontMediumItalic = css`
  font-family: "robotobold_italic", sans-serif !important;
`;

export const FontRegular = css`
  font-family: "robotoregular", sans-serif !important;
`;

interface CardWrapperProps extends ListProps<unknown> {
  flex?: string;
}

export const CardWrapper = styled(List)<CardWrapperProps>`
  &.ant-list {
    border: 0px !important;
  }
  min-width: 15rem;
  min-height: 12.5rem;
  background-color: ${({ theme }) => theme.card.background};
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
  .ant-list-header {
    background-color: ${({ theme }) => theme.primary} !important;
    border-top-left-radius: ${({ theme }) => theme.borderRadius};
    border-top-right-radius: ${({ theme }) => theme.borderRadius};
    padding-block: 0 !important;
    padding-inline: 0.5rem !important;
    border-block-end: none !important;
    min-height: 42px !important;
    /* height: 42px !important; */

    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 1280px) {
    /* min-width: unset; */
  }
  border-radius: ${({ theme }) => theme.borderRadius} !important;
`;

export const HeaderTitle = styled.h3`
  color: ${({ theme }) => theme.white};
  width: 100%;
  position: relative;
  font-size: 0.8rem;
  ${FontRegular}
`;

interface ItemProps {
  padding?: string;
}

export const Item = styled.h3`
  ${FontRegular}
  width: 100%;
  display: flex;
  color: ${({ theme }) => theme.item.key};
  font-size: 0.8rem;
  justify-content: space-between;
  span {
    color: ${({ theme }) => theme.item.value};
    ${FontBold}
  }
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  :last-child {
    border-bottom: none;
  }
`;

export const LightColor = styled.span``;

export const KeyValueDisplay = styled.div`
  overflow: hidden;
  height: 100%;
  padding: 0.625rem 1.25rem;
  h1 {
    font-size: 0.9rem;
  }
  span {
    color: ${({ theme }) => theme.item.key};
  }
`;

export const KeyValueWrapper = styled.div`
  display: flex;
  div {
    flex: 1;
  }
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

export const ContentWrapper = styled.div`
  padding: 0.5rem;
`;

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

// checking
