import { Layout as AntdLayout, Avatar, Dropdown, Image, Menu } from "antd";
import { styled } from "styled-components";
import { AppButton, AppDropdown } from "../components/common";
import { useUser } from "contexts/user-context";
import { NiftyStatComponent } from "page-components/dashboard/nifty-stat";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserOutlined, DollarOutlined, LogoutOutlined } from '@ant-design/icons';
import { KillSwitch } from "page-components/dashboard/quick-settings/kill-switch";
import { TerminalLock } from "page-components/dashboard/quick-settings/take-break";

const { Header } = AntdLayout;

const HeaderLayout = styled.div`
  height: 3.125rem !important;
  padding: 0px 16px !important;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.primary} !important;
  box-shadow: none;
  /* height: auto; */

  @media screen and (max-width: 1023px) {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
  }

  .header-row {
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
`;

const ButtonLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const items = [
  {
    key: '1',
    icon: <UserOutlined />,
    label: 'Profile',
  },
  {
    key: '2',
    icon: <DollarOutlined />,
    label: 'Billing',
  },
  {
    key: '3',
    icon: <LogoutOutlined />,
    label: 'Logout',
  },
];

const ProfileDropdown = () => {
  return (
    <Dropdown 
      menu={{ items }}
      placement="bottomRight" arrow trigger={['click']}>
      <Avatar
        src="https://via.placeholder.com/40"
        style={{ cursor: 'pointer' }}
      />
    </Dropdown>
  );
};

export const HeaderComponent = () => {
  const { logout } = useUser();
  const { t } = useTranslation();

  const doLogout = () => {
    logout();
  };
  return (
    <HeaderLayout>
      <div className="d-flex justify-content-end align-items-center">
        <img
          src="https://placehold.it/100x30"
          alt="logo image"
          width={100}
          height={30}
        />

        <NiftyStatComponent />
      </div>
      <div className="d-flex justify-content-end">
        <ButtonLayout>
          <Link to="/broker">Broker</Link>
          <TerminalLock />
          <KillSwitch />
          <ProfileDropdown />
          {/* <AppButton onClick={doLogout}>
            {t("header.nifty_section.log_out")}
          </AppButton> */}
        </ButtonLayout>
      </div>
    </HeaderLayout>
  );
};
