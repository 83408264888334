import { Item, ItemWrapper } from "components";
import { useTranslation } from "react-i18next";

export const TradeStatComponent = () => {
  const { t } = useTranslation();

  return (
    <ItemWrapper>
      <Item>
        {t("overview.trade_stat_component.num_trades")}
        <span>10</span>
      </Item>
      <Item>
        {t("overview.trade_stat_component.profit_factor")}
        <span>10</span>
      </Item>
      <Item>
        {t("overview.trade_stat_component.win_rate")}
        <span>50%</span>
      </Item>
      <Item>
        {t("overview.trade_stat_component.max_consec_wins")}
        <span>5</span>
      </Item>
      <Item>
        {t("overview.trade_stat_component.avg_time_in_trade")}
        <span>5 mins</span>
      </Item>
      <Item>
        {t("overview.trade_stat_component.max_consec_losses")}
        <span>5</span>
      </Item>
    </ItemWrapper>
  );
};
