import { AppTag, CustomTable } from "components";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";

export const HedgeListTable = styled(CustomTable)`
  max-height: 30rem;
  overflow: scroll;
`;

const HedgeItemsTable = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("mtm.positions_hedges_common_fields.symbol"),
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: t("mtm.positions_hedges_common_fields.avg_price"),
      dataIndex: "avgPrice",
      key: "avgPrice",
    },
    {
      title: t("mtm.positions_hedges_common_fields.qty"),
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: t("mtm.positions_hedges_common_fields.stop_loss"),
      dataIndex: "sl",
      key: "sl",
    },
    {
      title: t("mtm.positions_hedges_common_fields.target"),
      dataIndex: "target",
      key: "target",
    },
    {
      title: t("mtm.positions_hedges_common_fields.position"),
      dataIndex: "position",
      key: "position",
      render: (text: string) => (
        <div className="d-flex" key={text}>
          <AppTag color={text === "Long" ? "green" : "volcano"}>
            {text === "Long" ? "L" : "S"}
          </AppTag>
        </div>
      ),
    },
    {
      title: t("mtm.positions_hedges_common_fields.ltp"),
      dataIndex: "ltp",
      key: "ltp",
    },
    {
      title: t("mtm.positions_hedges_common_fields.pnl"),
      dataIndex: "pl",
      key: "pl",
    },
    {
      title: t("mtm.positions_hedges_common_fields.action"),
      dataIndex: "action",
      key: "action",
    },
  ];

  const data = [
    {
      key: "1",
      symbol: "AAPL",
      avgPrice: 150.25,
      qty: 100,
      sl: 145.0,
      target: 160.0,
      position: "Long",
      ltp: 152.0,
      pl: 175.0,
      action: "Hold",
    },
    {
      key: "2",
      symbol: "TSLA",
      avgPrice: 650.75,
      qty: 50,
      sl: 640.0,
      target: 700.0,
      position: "Short",
      ltp: 655.0,
      pl: 212.5,
      action: "Hold",
    },
    {
      key: "3",
      symbol: "GOOGL",
      avgPrice: 2725.0,
      qty: 30,
      sl: 2700.0,
      target: 2800.0,
      position: "Long",
      ltp: 2730.0,
      pl: 150.0,
      action: "Hold",
    },
    {
      key: "4",
      symbol: "AMZN",
      avgPrice: 3300.5,
      qty: 20,
      sl: 3250.0,
      target: 3400.0,
      position: "Short",
      ltp: 3350.0,
      pl: 990.0,
      action: "Sell",
    },
    {
      key: "5",
      symbol: "MSFT",
      avgPrice: 300.4,
      qty: 75,
      sl: 295.0,
      target: 310.0,
      position: "Long",
      ltp: 305.0,
      pl: 345.0,
      action: "Hold",
    },
    {
      key: "6",
      symbol: "NFLX",
      avgPrice: 500.0,
      qty: 40,
      sl: 490.0,
      target: 520.0,
      position: "Long",
      ltp: 510.0,
      pl: 400.0,
      action: "Hold",
    },
    {
      key: "7",
      symbol: "FB",
      avgPrice: 330.0,
      qty: 60,
      sl: 320.0,
      target: 350.0,
      position: "Short",
      ltp: 340.0,
      pl: 600.0,
      action: "Sell",
    },
    {
      key: "8",
      symbol: "NVDA",
      avgPrice: 750.0,
      qty: 25,
      sl: 740.0,
      target: 780.0,
      position: "Long",
      ltp: 760.0,
      pl: 250.0,
      action: "Hold",
    },
    {
      key: "9",
      symbol: "DIS",
      avgPrice: 180.0,
      qty: 100,
      sl: 175.0,
      target: 190.0,
      position: "Long",
      ltp: 185.0,
      pl: 500.0,
      action: "Hold",
    },
    {
      key: "10",
      symbol: "BABA",
      avgPrice: 200.0,
      qty: 50,
      sl: 195.0,
      target: 210.0,
      position: "Short",
      ltp: 205.0,
      pl: 250.0,
      action: "Sell",
    },
    {
      key: "11",
      symbol: "ORCL",
      avgPrice: 90.0,
      qty: 150,
      sl: 85.0,
      target: 95.0,
      position: "Long",
      ltp: 92.0,
      pl: 300.0,
      action: "Hold",
    },
    {
      key: "12",
      symbol: "INTC",
      avgPrice: 55.0,
      qty: 200,
      sl: 52.0,
      target: 60.0,
      position: "Short",
      ltp: 57.0,
      pl: 400.0,
      action: "Hold",
    },
    {
      key: "13",
      symbol: "V",
      avgPrice: 220.0,
      qty: 70,
      sl: 215.0,
      target: 230.0,
      position: "Long",
      ltp: 225.0,
      pl: 350.0,
      action: "Hold",
    },
    {
      key: "14",
      symbol: "JPM",
      avgPrice: 150.0,
      qty: 80,
      sl: 145.0,
      target: 155.0,
      position: "Short",
      ltp: 152.0,
      pl: 160.0,
      action: "Hold",
    },
    {
      key: "15",
      symbol: "BAC",
      avgPrice: 40.0,
      qty: 300,
      sl: 38.0,
      target: 45.0,
      position: "Long",
      ltp: 42.0,
      pl: 600.0,
      action: "Hold",
    },
  ];

  return (
    <HedgeListTable
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey="key"
    />
  );
};

export default HedgeItemsTable;
