import { Button, Dropdown, Radio } from "antd";
import useTranslatedMenuItems, {
  BlueButton,
  Buy,
  Content,
  Form,
  FormItem,
  Header,
  HeaderDropdown,
  PercentageButtonsBuy,
  PercentageButtonsSell,
  Sell,
  SlTargetForm,
  StyledAppButton,
  StyledAppInput,
} from "./component/common";
import OptionChain from "./component/option-chain-chart";
import { BasketModal } from "./component/basket";
import { styled } from "styled-components";
import { Minus, Plus } from "@phosphor-icons/react";
import { AppButton, AppInput, SectionLayout } from "components";
import { useTranslation } from "react-i18next";

const BuySell = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  .buy {
    border-right: 1px dashed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  .sell {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
`;

const Title = styled.h1`
  font-size: 1.125rem;
  text-align: center;
`;

const BuyTitle = styled(Title)`
  color: ${({ theme }) => theme.darkGreen} !important;
  position: relative;
  span {
    display: block;
    max-width: 150px;
  }
  svg {
    position: absolute;
    color: black;
  }
`;

const SellTitle = styled(Title)`
  color: ${({ theme }) => theme.red} !important;
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  margin: 4px 0px !important;
`;

const HorizontalFormBuy = () => {
  const onFinish = (values: any) => {
    console.log("Received values:", values);
  };
  const { t } = useTranslation();

  return (
    <Form layout="inline" onFinish={onFinish}>
      <FormItem name="quantity" rules={[{ max: 5 }]}>
        <StyledAppInput
          type="number"
          placeholder={t("terminal.common_options.placeholder.qty")}
          maxLength={5}
        />
      </FormItem>

      <FormItem name="price" rules={[{ max: 6 }]}>
        <StyledAppInput
          type="number"
          placeholder={t("terminal.common_options.placeholder.price")}
          maxLength={6}
        />
      </FormItem>
      <StyledAppButton type="primary" htmlType="submit" className="green">
        {t("terminal.common_options.placeholder.buy")}
      </StyledAppButton>
      <StyledAppButton type="primary" htmlType="submit" className="red">
        {t("terminal.common_options.placeholder.sell")}
      </StyledAppButton>
    </Form>
  );
};

const HorizontalFormSell = () => {
  const onFinish = (values: any) => {
    console.log("Received values:", values);
  };
  const { t } = useTranslation();

  return (
    <Form layout="inline" onFinish={onFinish}>
      <FormItem name="quantity" rules={[{ max: 5 }]}>
        <StyledAppInput
          type="number"
          placeholder={t("terminal.common_options.placeholder.qty")}
          maxLength={5}
        />
      </FormItem>

      <FormItem name="price" rules={[{ max: 6 }]}>
        <StyledAppInput
          type="number"
          placeholder={t("terminal.common_options.placeholder.price")}
          maxLength={6}
        />
      </FormItem>
      <StyledAppButton type="primary" htmlType="submit" className="green">
        {t("terminal.common_options.placeholder.buy")}
      </StyledAppButton>
      <StyledAppButton type="primary" htmlType="submit" className="red">
        {t("terminal.common_options.placeholder.sell")}
      </StyledAppButton>
    </Form>
  );
};

const TitleContainer = styled.div`
  display: flex;
  gap: 2;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
`;

const TitleWithIcons = ({ text }: any) => {
  return (
    <TitleContainer>
      <div>
        <AppButton size="small" icon={<Minus size={16} weight="bold" />} />
      </div>
      {text}
      <div>
        <AppButton size="small" icon={<Plus size={16} weight="bold" />} />
      </div>
    </TitleContainer>
  );
};

export const OptionsDirectionalTerminal = () => {
  const {
    exchangeItems,
    callItems,
    productItems,
    symbolItems,
    putItems,
    typeItems,
    expiryItems,
    lotItems,
    validityItems,
  } = useTranslatedMenuItems();
  const { t } = useTranslation();

  return (
    <>
      <SectionLayout
        header={null}
        content={
          <Content className="px-2">
            <Header>
              <div className="d-flex flex-column flex-1 gap-1">
                <Dropdown
                  menu={{ items: exchangeItems }}
                  placement="bottomLeft"
                >
                  <HeaderDropdown>
                    {t("terminal.common_options.exchange_options.exchange")}
                  </HeaderDropdown>
                </Dropdown>

                <Dropdown menu={{ items: callItems }} placement="bottomLeft">
                  <HeaderDropdown>
                    {t("terminal.common_options.call")}
                  </HeaderDropdown>
                </Dropdown>

                <Dropdown menu={{ items: productItems }} placement="bottomLeft">
                  <HeaderDropdown>
                    {t("terminal.common_options.product_options.product")}
                  </HeaderDropdown>
                </Dropdown>
              </div>

              <div className="d-flex flex-column flex-1 gap-1">
                <Dropdown menu={{ items: symbolItems }} placement="bottomLeft">
                  <HeaderDropdown>
                    {t("terminal.common_options.symbol_options.symbol")}
                  </HeaderDropdown>
                </Dropdown>

                <Dropdown menu={{ items: putItems }} placement="bottomLeft">
                  <HeaderDropdown>
                    {t("terminal.common_options.put")}
                  </HeaderDropdown>
                </Dropdown>

                <Dropdown menu={{ items: typeItems }} placement="bottomLeft">
                  <HeaderDropdown>
                    {t("terminal.common_options.type_options.type")}
                  </HeaderDropdown>
                </Dropdown>
              </div>

              <div className="d-flex flex-column flex-1 gap-1">
                <Dropdown menu={{ items: expiryItems }} placement="bottomLeft">
                  <HeaderDropdown>
                    {t("terminal.common_options.expiry_options.expiry")}
                  </HeaderDropdown>
                </Dropdown>

                <Dropdown menu={{ items: lotItems }} placement="bottomLeft">
                  <HeaderDropdown>
                    {t("terminal.common_options.lots_options.lots")}
                  </HeaderDropdown>
                </Dropdown>

                <Dropdown
                  menu={{ items: validityItems }}
                  placement="bottomLeft"
                >
                  <HeaderDropdown>
                    {t("terminal.common_options.validity_options.validity")}
                  </HeaderDropdown>
                </Dropdown>
              </div>

              <div className="d-flex flex-column flex-1 gap-1">
                <OptionChain />
                <BasketModal />
              </div>
            </Header>
            <BuySell>
              <div className="buy">
                <TitleWithIcons
                  text={<BuyTitle>BANK NIFTY feb 21 47800 CE</BuyTitle>}
                />

                <div className="mx-2 d-flex flex-column mb-2">
                  <AppInput
                    placeholder={t("terminal.common_options.placeholder.price")}
                    placeholderalign="center"
                    size="large"
                  />
                  <Buy className="mt-2" size="large">
                    {t("terminal.common_options.buy_call")}
                  </Buy>
                  <Sell className="mt-2" size="large">
                    {t("terminal.common_options.sell_call")}
                  </Sell>
                </div>

                <PercentageButtonsBuy />
                <PercentageButtonsSell />

                <div className="me-2 mt-2">
                  <SlTargetForm />
                </div>

                <div className="mt-2 me-2">
                  <h3>{t("terminal.common_options.limit")}</h3>
                  <HorizontalFormBuy />
                </div>
              </div>

              <div className="sell">
                <TitleWithIcons
                  text={<SellTitle>BANK NIFTY feb 21 47800 PE</SellTitle>}
                />

                <div className="mx-2 d-flex flex-column mb-2">
                  <AppInput
                    placeholder={t("terminal.common_options.placeholder.price")}
                    placeholderalign="center"
                    size="large"
                  />
                  <Buy className="mt-2" size="large">
                    {t("terminal.common_options.buy_put")}
                  </Buy>
                  <Sell className="mt-2" size="large">
                    {t("terminal.common_options.sell_put")}
                  </Sell>
                </div>

                <PercentageButtonsBuy />
                <PercentageButtonsSell />

                <div className="ms-2 mt-2">
                  <SlTargetForm />
                </div>

                <div className="mt-2 ms-2">
                  <h3> {t("terminal.common_options.limit")} </h3>
                  <HorizontalFormSell />
                </div>
              </div>
            </BuySell>

            <div className="d-flex justify-content-center gap-2">
              <BlueButton size="large">
                {t("terminal.common_options.blue_button.exit_all")}
              </BlueButton>
              <BlueButton size="large">
                {t("terminal.common_options.blue_button.cancel_all")}
              </BlueButton>
            </div>
          </Content>
        }
      />
    </>
  );
};
