import React, { createContext, useState, useContext, useEffect } from "react";
import { STORAGE, Storage } from "utilities";

interface User {
  token: string | null;
}

interface UserContextType {
  user: User;
  login: (userData: User) => void;
  logout: () => void;
}

export const UserContext = createContext<UserContextType>({
  user: { token: Storage.getItem(STORAGE.TOKEN) },
  login: () => {},
  logout: () => {},
});

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User>({
    token: Storage.getItem(STORAGE.TOKEN),
  });
  // const router = useRouter();

  const login = (userData: User) => {
    setUser(userData);
  };

  useEffect(() => {
    if (!user.token) {
      const token = Storage.getItem(STORAGE.TOKEN);
      if (token) {
        setUser({ token });
      }
    } else {
      Storage.setItem({
        key: STORAGE.TOKEN,
        value: user.token,
      });
    }
  }, [user]);

  const logout = () => {
    Storage.removeItem(STORAGE.TOKEN);
    setUser({ token: null });
    // router.push(PAGE_ROUTE.LOGIN);
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
