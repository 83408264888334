import React, { useState } from "react";
import { Col, Row, Slider, Space, Typography, Input } from "antd";
import { AppButton, AppInput } from "./common";
import { Minus, Plus } from "@phosphor-icons/react";
import styled from "styled-components";

interface CounterProps {
  label: string;
  step: number;
  min: number;
  max: number;
  onValueChange: (label: string, value: number) => void;
}

const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem;
`;

const CounterSection = styled(Space.Compact)`
  margin-left: 1rem;
  input {
    width: 3.5rem;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
    text-align: center;
  }
`;

export const Counter: React.FC<CounterProps> = ({ label, step, min, max, onValueChange }) => {
  const [inputValue, setInputValue] = useState<number | string>(min);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.trim() === '') {
      setInputValue('');
      return;
    }
    
    const numberValue = Number(value);
    if (!isNaN(numberValue)) {
      if (numberValue >= min && numberValue <= max) {
        setInputValue(numberValue);
        onValueChange(label, numberValue);
      } else if (numberValue < min) {
        setInputValue(min);
        onValueChange(label, min);
      } else if (numberValue > max) {
        setInputValue(max);
        onValueChange(label, max);
      }
    }
  };

  const increment = () => {
    setInputValue((prev) => {
      const newValue = (typeof prev === 'number' ? prev : min) + step;
      if (newValue <= max) {
        onValueChange(label, newValue);
        return newValue;
      }
      return prev;
    });
  };

  const decrement = () => {
    setInputValue((prev) => {
      const newValue = (typeof prev === 'number' ? prev : min) - step;
      if (newValue >= min) {
        onValueChange(label, newValue);
        return newValue;
      }
      return prev;
    });
  };

  const onInputBlur = () => {
    if (inputValue === '') {
      setInputValue(min);
      onValueChange(label, min);
    }
  };

  return (
    <RadioGroupContainer>
      <Typography.Title level={5}>{label}</Typography.Title>
      <Row>
        <Col span={14}>
          <Slider
            min={min}
            max={max}
            step={step}
            value={typeof inputValue === 'number' ? inputValue : min}
            onChange={(value) => setInputValue(value)}
          />
        </Col>

        <Col span={4}>
          <CounterSection>
            <AppButton icon={<Minus />} onClick={decrement} />
            <AppInput
              type="number"
              value={inputValue}
              step={step}
              min={min}
              max={max}
              onChange={onChange}
              onBlur={onInputBlur}
            />
            <AppButton icon={<Plus />} onClick={increment} />
          </CounterSection>
        </Col>
      </Row>
    </RadioGroupContainer>
  );
};
