import { FontRegular } from 'components'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`

@font-face {
    font-family: 'robotobold';
    src: url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('../fonts/roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotobold_italic';
    src: url('../fonts/roboto-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/roboto-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotomedium';
    src: url('../fonts/roboto-medium-webfont.woff2') format('woff2'),
         url('../fonts/roboto-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotomedium_italic';
    src: url('../fonts/roboto-mediumitalic-webfont.woff2') format('woff2'),
         url('../fonts/roboto-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoregular';
    src: url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

  * {
    ${FontRegular}
  }

  body,
  html{
    margin: 0;
  }

  html {
    scroll-behavior: smooth;
  }  

  body {
    margin: 0;
    padding: 0;
    ${FontRegular}
    background-color: ${({ theme }) => theme.background};
  }
  button {
    ${FontRegular}
  }
  h1, h2, h3, h4, h5, h6 {
    ${FontRegular}
    margin: 0px;
    padding: 0px;
  }
  p {
    ${FontRegular}
    margin: 0px;
    padding: 0px;
  }


  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
`
