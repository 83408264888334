import { styled } from "styled-components";
import { Space, TabsProps, Tooltip } from "antd";
import { LogoutOutlined, ReloadOutlined } from "@ant-design/icons";

import { PositionsTable } from "./mtm/positions";
import { OrderBookTable } from "./mtm/order-book";
import { TradeBookTable } from "./mtm/trade-book";
import HedgeItemsTable from "./mtm/hedge-items";
import {
  AppButton,
  ContentWrapper,
  CustomTabs,
  SectionHeaderTitle,
  SectionLayout,
} from "components";
import { useTranslation } from "react-i18next";
import { ArrowsClockwise, Lock, LockOpen, Minus, Plus } from "@phosphor-icons/react";
import { useState } from "react";
import { PositionsDisplay } from "./quick-settings/positions-display";
import { Typography } from 'antd';

const { Title } = Typography;

const MtmTitle = styled(Title)`
  margin-bottom: 0px !important;
`

const MtmWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const MtmAction = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
`;

export const MtmComponent = () => {

  const [isExpanded, setIsExpanded] = useState(true);
  const [isLocked, setIsLocked] = useState(true);

  const onChange = (key: string) => {};
  const { t } = useTranslation();

  const minimizeWindow = () => {
    if (isLocked) {
      if (!isExpanded) {
        setIsExpanded(true);
      }
    } else {
      setIsExpanded(!isExpanded);
    }
  }

  const items: TabsProps["items"] = [
    {
      key: "positions",
      label: (
        <div className="d-flex align-items-center gap-1 justify-content-center">
          {t("mtm.tab_screen.positions")}
          <PositionsDisplay />
        </div>
      ),
      children: isExpanded ? <PositionsTable /> : null,
    },
    {
      key: "hedges",
      label: t("mtm.tab_screen.hedges"),
      children: isExpanded ? <HedgeItemsTable /> : null,
    },
    {
      key: "history",
      label: t("mtm.tab_screen.history"),
      children: isExpanded ? <PositionsTable /> : null,
    },
    {
      key: "strategy_trades",
      label: t("mtm.tab_screen.strategy_trades"),
      children: isExpanded ? <PositionsTable /> : null,
    },
    {
      key: "orderbook",
      label: t("mtm.tab_screen.orderbook"),
      children: isExpanded ? <OrderBookTable /> : null,
    },
    {
      key: "tradebook",
      label: t("mtm.tab_screen.tradebook"),
      children: isExpanded ? <TradeBookTable /> : null,
    },    
  ];

  return (
    <SectionLayout
      header={null}
      content={
        <>
          <ContentWrapper>
            <MtmWrapper>
              <CustomTabs
                type="card"
                defaultActiveKey="1"
                items={items}
                onChange={onChange}
              />
              <MtmAction>
                <Space>

                  <MtmTitle level={5}>MTM: 12,35,679</MtmTitle>

                  <Tooltip title={t("mtm.refresh")}>
                    <AppButton type="primary" icon={<ArrowsClockwise size={16} />} />
                  </Tooltip>

                  <AppButton type="primary" icon={<LogoutOutlined />}>
                    {t("mtm.exit_all")}
                  </AppButton>

                  <Tooltip
                    title={isExpanded ? t("mtm.tab_screen.minimize_help") : t("common.expand")}
                  >
                    <AppButton
                      onClick={minimizeWindow}
                      type="primary"
                      icon={isExpanded ? <Minus size={16} /> : <Plus size={16} />}
                    />
                  </Tooltip>

                  <Tooltip
                    title={isLocked ? t("mtm.tab_screen.unlock_description") : t("mtm.tab_screen.lock_description")}
                  >
                    <AppButton
                      onClick={() => setIsLocked(!isLocked)}
                      type="primary"
                      icon={isLocked ? <Lock size={16} /> : <LockOpen size={16} />}
                    />
                  </Tooltip>                  
                </Space>
              </MtmAction>
            </MtmWrapper>
          </ContentWrapper>
        </>
      }
    />
  );
};
