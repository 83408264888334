// import { Link } from "react-router-dom";

// export const BrokerListScreen = () => {
//     return (
//         <>
//             <h1>Broker List</h1>
//             <Link to="/broker/add">Add</Link>

//             <Link to="/broker/details/1">Details</Link>
//         </>
//     );
// }

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BROKERS } from 'utilities';
import api from 'utilities/services/api';

interface Broker {
  id: number;
  name: string;
  // Add other broker properties as needed
}

export const BrokerListScreen: React.FC = () => {
  const [brokers, setBrokers] = useState<Broker[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBrokers = async () => {
      try {
        const response = await api.get<Broker[]>(BROKERS.LIST);
        setBrokers(response as any);
      } catch (err) {
        setError('Failed to fetch brokers');
      } finally {
        setLoading(false);
      }
    };

    fetchBrokers();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <h1>Broker List</h1>
      <Link to="/broker/add">Add</Link>
      <ul>
        {brokers.map((broker) => (
          <li key={broker.id}>
            {broker.name} - <Link to={`/broker/details/${broker.id}`}>Details</Link>
          </li>
        ))}
      </ul>
    </>
  );
}
