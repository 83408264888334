import { Table } from "antd";
import { styled } from "styled-components";
import { FontRegular } from "./common";

export const CustomTable = styled(Table)`
  .ant-table {
    border: 1px solid #e8e8e8;
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
  }

  .ant-table-tbody > tr > td:last-child,
  .ant-table-thead > tr > th:last-child {
    border-right: none;
  }
  width: 100% !important;
  td {
    padding: 0.5rem 0.25rem !important;
    ${FontRegular}
  }
  th {
    padding: 0.5rem 0.25rem !important;
    ${FontRegular};
  }
  .left {
    text-align: left !important;
  }
  .ant-table-thead > tr > th {
    &.left {
      text-align: left !important;
    }
    &.center {
      text-align: center !important;
    }
    &.right {
      text-align: right !important;
    }
  }  
`;

interface WatchListTableProps {
  maxHeight?: string;
}

export const WatchListTable = styled(CustomTable)<WatchListTableProps>`
  max-height: ${({ maxHeight }) => maxHeight || '12rem'};
  overflow: scroll;
`;
