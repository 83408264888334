import { AppButton, AppInput, Counter } from "components";
import { AvailablCapital } from "./available-capital";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

export const TradeLevel = () => {
  const handleCounterChange = (label: string, value: number) => {
    console.log(`${label}: ${value}`);
  };
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <>
      <AvailablCapital />
      <h2>
        {t("risk_manage_system.trade_model.capital_allocation_per_trade")}
      </h2>

      <Counter
        label={t(
          "risk_manage_system.trade_model.capital_risk_per_trade_percent"
        )}
        step={1}
        min={1}
        max={100}
        onValueChange={handleCounterChange}
      />

      <Counter
        label={t("risk_manage_system.trade_model.fixed_risk_per_trade")}
        step={1}
        min={1}
        max={100}
        onValueChange={handleCounterChange}
      />

      <Form form={form} layout="vertical">
        <Form.Item
          label={t(
            "risk_manage_system.trade_model.fixed_capital_per_trade"
          )}
        >
          <AppInput type="number" placeholder="" />
        </Form.Item>

        <Form.Item
          label={t(
            "risk_manage_system.trade_model.fixed_contract_per_trade"
          )}
        >
          <AppInput type="number" placeholder="" />
        </Form.Item>
      </Form>

      <AppButton size="large" type="primary">
        {t("quick_setting.modal.save_settings")}
      </AppButton>
    </>
  );
};
