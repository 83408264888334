import { Tabs } from "antd";
import { styled } from "styled-components";

export const CustomTabs = styled(Tabs)`
  width: 100% !important;
  .ant-tabs-nav {
    margin-bottom: 0.5rem !important;
  }
  .ant-tabs-nav .ant-tabs-tab {
    border-radius: 0 !important;
  }

  .ant-tabs-content-holder {
    border-radius: 0 !important;
  }
`;