import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
interface MarketData {
  name: string;
  value: number;
  change: string;
  isPositive: boolean;
}

const MarketStatus = styled.div`
  display: flex;
  p {
    font-size: 0.7rem;
    color: ${({ theme }) => theme.white};
  }
  font-size: 1.5em;
  .green {
    font-size: 0.7rem;
    color: ${({ theme }) => theme.green};
    font-weight: bold;
    span {
      font-size: 0.9rem;
      margin-left: 0.5rem;
    }
  }

  .red {
    font-size: 0.7rem;
    color: ${({ theme }) => theme.red};
    font-weight: bold;
    span {
      font-size: 0.9rem;
      margin-left: 0.5rem;
    }
  }
`;

export const NiftyStatComponent = () => {
  const { t } = useTranslation();
  const marketData: MarketData[] = [
    {
      name: t("header.nifty_section.nifty"),
      value: 2222,
      change: "+283 (1.1%)",
      isPositive: true,
    },
    {
      name: t("header.nifty_section.nifty_bank"),
      value: 2222,
      change: "+283 (1.1%)",
      isPositive: false,
    },
    {
      name: t("header.nifty_section.sensex"),
      value: 2222,
      change: "+283 (1.1%)",
      isPositive: true,
    },
    {
      name: t("header.nifty_section.fininfty"),
      value: 2222,
      change: "+283 (1.1%)",
      isPositive: false,
    },
  ];

  return (
    <div className="d-flex align-items-center gap-4 ms-2">
      {marketData.map((market, index) => (
        <MarketStatus key={index}>
          <div className="d-flex flex-column">
            <p>{market.name}</p>
            <h4 className={market.isPositive ? "green" : "red"}>
              {market.change}
              <span>{market.value}</span>
            </h4>
          </div>
        </MarketStatus>
      ))}
    </div>
  );
};
