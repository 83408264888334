import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import {
  BrokerAddScreen,
  BrokerDetailsScreen,
  BrokerListScreen,
  ContactUsScreen,
  DashboardScreen,
  ForgotPasswordScreen,
  LoginScreen,
  NotFoundScreen,
  PricingScreen,
  RegisterScreen,
} from "screens";
import { ThemeProvider } from "styled-components";
import { useState } from "react";
import { UserProvider } from "contexts/user-context";
import { darkTheme, lightTheme } from "utilities";
import { GlobalStyles } from "global";
import PrivateRoute from "components/private-route";

function App() {
  const [theme, setTheme] = useState("light");
  return (
    <>
      <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
        <UserProvider>
          <GlobalStyles />
          <Router>
            <Routes>
              <Route path="/" element={<LoginScreen />} />
              <Route path="register" element={<RegisterScreen />} />
              <Route
                path="forgot-password"
                element={<ForgotPasswordScreen />}
              />
              <Route path="pricing" element={<PricingScreen />} />
              <Route path="contact-us" element={<ContactUsScreen />} />
              <Route path="not-found" element={<NotFoundScreen />} />

              <Route element={<PrivateRoute />}>
                <Route path="dashboard" element={<DashboardScreen />} />

                <Route path="broker" element={<Outlet />}>
                  <Route index element={<BrokerListScreen />} />
                  <Route path="add" element={<BrokerAddScreen />} />
                  <Route path="details/:id" element={<BrokerDetailsScreen />} />
                </Route>
              </Route>

              <Route path="*" element={<Navigate to="/not-found" replace />} />
            </Routes>
          </Router>
        </UserProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
