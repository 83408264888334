import React from "react";
import { AppModal } from "./common";

interface ConfirmationDialogProps {
  title: string;
  content: string;
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  okText?: string;
  cancelText?: string;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  content,
  visible,
  onOk,
  onCancel,
  okText = "OK",
  cancelText = "Cancel",
}) => {
  return (
    <AppModal
      title={title}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
    >
      <p>{content}</p>
    </AppModal>
  );
};

