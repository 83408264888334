import { ConfirmationDialog, QuickSettingItem } from "components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { showSuccessNotification } from "utilities";

export const KillSwitch = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    showSuccessNotification(`ok`);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <QuickSettingItem
        onClick={() => showModal()}
        buttonText={t("kill_switch.kill_switch_placeholder")}
        type="button"
      />

      <ConfirmationDialog
        title={t("kill_switch.title")}
        content={t("kill_switch.content")}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      />
    </>
  );
};
