import { AppButton, Counter } from "components";
import { AvailablCapital } from "./available-capital";
import { useTranslation } from "react-i18next";

export const SegmentLevel = () => {
  const handleCounterChange = (label: string, value: number) => {
    console.log(`${label}: ${value}`);
  };
  const { t } = useTranslation();

  return (
    <>
      <AvailablCapital />

      <h2>
        {t(
          "risk_manage_system.segment_model.capital_allocation_per_segment"
        )}
      </h2>

      <Counter
        label={t("risk_manage_system.segment_model.equity")}
        step={0.5}
        min={1}
        max={100}
        onValueChange={handleCounterChange}
      />
      <Counter
        label={t("risk_manage_system.segment_model.futures")}
        step={0.5}
        min={1}
        max={100}
        onValueChange={handleCounterChange}
      />
      <Counter
        label={t("risk_manage_system.segment_model.options")}
        step={0.5}
        min={1}
        max={100}
        onValueChange={handleCounterChange}
      />

      <AppButton size="large">
        {t("quick_setting.modal.save_settings")}
      </AppButton>
    </>
  );
};
