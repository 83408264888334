import { useState } from "react";
import type { RadioChangeEvent } from "antd";
import { IMenuItem } from "models";
import { QuickSettingItem } from "components";
import { useTranslation } from "react-i18next";

export const PositionsDisplay = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const items: IMenuItem[] = [
    { key: "1", label: t("positions_display.todays_trades") },
    { key: "2", label: t("positions_display.todays_fo_positions_only") },
    { key: "3", label: t("positions_display.todays_equity_positions_only") },
    { key: "4", label: t("positions_display.all_fo_positions") },
    { key: "5", label: t("positions_display.all_equity_positions") },
  ];

  return (
    <>
      <QuickSettingItem
        type="icon-only"
        buttonText={t("positions_display.positions_display_placeholder")}
        items={items}
      />
    </>
  );
};
