import { useState } from "react";
import { Radio, Space } from "antd";
import type { RadioChangeEvent } from "antd";
import { showSuccessNotification } from "utilities";
import { AppModal, AppRadioGroup, QuickSettingItem } from "components";
import { useTranslation } from "react-i18next";

export const TerminalLock = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    showSuccessNotification(`Terminal locked for ${value} mins`);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <>
      <QuickSettingItem
        onClick={() => showModal()}
        buttonText={t("terminal_lock.terminal_lock_placeholder")}
        type="button"
      />

      <AppModal
        title={t("terminal_lock.title")}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <AppRadioGroup onChange={onChange} value={value}>
          <Space direction="vertical">
            <Radio value={30}>{t("terminal_lock.30_mins")}</Radio>
            <Radio value={60}>{t("terminal_lock.1_hr")}</Radio>
            <Radio value={120}>{t("terminal_lock.2_hrs")}</Radio>
            <Radio value={240}>{t("terminal_lock.4_hrs")}</Radio>
          </Space>
        </AppRadioGroup>
      </AppModal>
    </>
  );
};
