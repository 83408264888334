import React from "react";
import { styled } from "styled-components";
import { AppButton, AppDropdown, AppInput, FontRegular } from "./common";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Select, Space } from "antd";
import { AppDropdownInfo, IMenuItem } from "models";
const { Option } = Select;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    display: flex;
    align-items: center;
    padding: 0px 10px 10px 0px !important;
  }
  .ant-select-arrow {
    margin-top: auto;
    margin-bottom: auto;
  }
`;
interface QuickSettingItemProps {
  type:
    | "button"
    | "input"
    | "input-notch"
    | "button-notch"
    | "input-notch-select"
    | "icon-only";
  buttonText?: string;
  placeholder?: string;
  notchIcon?: React.ReactNode;
  items?: IMenuItem[];
  onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  dropdownIcon?: React.ReactNode;
  onItemSelected?: (event: AppDropdownInfo) => void;
}

const ButtonWrapper = styled.div`
  display: flex;
  position: relative;
  button {
    width: 100%;
    ${FontRegular}
  }
  input {
    ${FontRegular}
  }
`;

export const QuickSettingItem: React.FC<
  QuickSettingItemProps & React.HTMLAttributes<HTMLDivElement>
> = ({
  type,
  buttonText,
  placeholder,
  notchIcon,
  items,
  onClick,
  onItemSelected,
  onChange,
  value,
  dropdownIcon,
}) => {
  return (
    <>
      <ButtonWrapper className="quick-setting-item">
        {type === "button" && (
          <AppButton onClick={onClick}>{buttonText}</AppButton>
        )}

        {type === "input" && (
          <AppInput
            placeholderalign="center"
            onChange={onChange}
            placeholder={placeholder}
          />
        )}

        {type === "input-notch-select" && (
          <Space.Compact>
            <AppInput
              placeholderalign="center"
              placeholder={placeholder}
              addonAfter={
                items && items.length ? (
                  <Select defaultValue={""} style={{ width: 80 }}>
                    {items.map((item) => (
                      <Option key={item.key} value={item.key}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                ) : null
              }
            />
          </Space.Compact>
        )}

        {type === "input-notch" && (
          <Space.Compact>
            <AppInput
              placeholderalign="center"
              placeholder={placeholder}
              addonAfter={notchIcon}
            />
          </Space.Compact>
        )}

        {type === "button-notch" && (
          <AppDropdown
            icon={dropdownIcon || <DownOutlined />}
            menu={{
              items,
              onClick: onItemSelected
                ? (selectedValue: any) => onItemSelected(selectedValue)
                : undefined,
            }}
          >
            {buttonText}
          </AppDropdown>
        )}

        {type === "icon-only" && (
          <Dropdown
            menu={{ items }}
            placement="bottomRight"
            arrow
            trigger={["click"]}
          >
            <DownOutlined style={{ cursor: "pointer" }} />
          </Dropdown>
        )}
      </ButtonWrapper>
    </>
  );
};
