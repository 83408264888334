import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { showErrorNotification } from './ui-utils';
import { Storage } from './storage';
import { STORAGE } from 'utilities/constants';
interface RequestOptions {
}

class Api {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create();

    this.instance.interceptors.request.use(
      (config) => {
        const token = Storage.getItem(STORAGE.TOKEN)
        console.log(token);
        if (token) {
          config.headers.authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        if (error.response) {
          if (error.response.status === 401) {
            // Storage.removeItem(STORAGE.TOKEN);
            // window.location.href = '/';
          }
          showErrorNotification(error.response.data.message);
        }
        return Promise.reject(error);
      }
    );
  }

  async get<T>(url: string, options?: RequestOptions): Promise<AxiosResponse<T>> {
    return this.instance.get<T>(url, options);
  }

  async post<T>(url: string, data?: any, options?: RequestOptions): Promise<any> {
    return this.instance.post<T>(url, data, options);
  }
}

const api = new Api();

export default api;
