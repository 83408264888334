import { styled } from "styled-components";
import { TerminalLock } from "./quick-settings/take-break";
import { KillSwitch } from "./quick-settings/kill-switch";
import { Terminal } from "./quick-settings/terminal";
import { RiskManagement } from "./quick-settings/risk-management";
import { DayLoss } from "./quick-settings/day-loss";
import { PositionsDisplay } from "./quick-settings/positions-display";
import { ProfitProtect } from "./quick-settings/profit-protect";
import { DayTarget } from "./quick-settings/day-target";
import { CardWrapper } from "components";

const QuickSettingItemWrapper = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
  .quick-setting-item {
    flex: 1;
    /* width: calc(33.33% - 0.8rem); */
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
    @media only screen and (max-width: 1280px) {
      width: calc(50% - 0.8rem);
    }
    button {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: border-box;
    }
  }
`;

const BoxWrapper = styled(CardWrapper)`
  flex-basis: 50%;
`;

export const QuickSettingComponent = () => {
  return (
    <QuickSettingItemWrapper>
      <Terminal />
      <RiskManagement />
      <ProfitProtect />
      <DayTarget />
      <DayLoss />
    </QuickSettingItemWrapper>
  );
};
