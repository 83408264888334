import { Col, Form, Radio, Row, Space } from "antd";
import { AppButton, AppTextArea, AppTimePicker } from "components";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export const Nudges = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const format = "h:mm a";

  return (
    <>
      <h2 className="mb-3">{t("quick_setting.nudges.title")}</h2>
      <Form form={form} layout="vertical">
        <Row gutter={24}>
          <Col className="gutter-row" span={12}>
            <Form.Item label={t("quick_setting.nudges.beginning_session")}>
              <AppTextArea
                rows={4}
                cols={10}
                showCount
                maxLength={250}
                placeholder=""
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label={t("quick_setting.nudges.break_reminder.title")}>
          <Radio.Group>
            <Radio value={1}>
              {t("quick_setting.nudges.break_reminder.1_hour")}
            </Radio>
            <Radio value={2}>
              {t("quick_setting.nudges.break_reminder.2_hours")}
            </Radio>
            <Radio value={3}>
              {t("quick_setting.nudges.break_reminder.3_hours")}
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={t("quick_setting.nudges.slow_down_after_profits.title")}
        >
          <Radio.Group>
            <Radio value={1}>{t("common.numbers.1")}</Radio>
            <Radio value={2}>{t("common.numbers.2")}</Radio>
            <Radio value={3}>{t("common.numbers.3")}</Radio>
            <Radio value={4}>{t("common.numbers.4")}</Radio>
            <Radio value={5}>{t("common.numbers.5")}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={t("quick_setting.nudges.slow_down_after_losses.title")}
        >
          <Radio.Group>
            <Radio value={1}>{t("common.numbers.1")}</Radio>
            <Radio value={2}>{t("common.numbers.2")}</Radio>
            <Radio value={3}>{t("common.numbers.3")}</Radio>
            <Radio value={4}>{t("common.numbers.4")}</Radio>
            <Radio value={5}>{t("common.numbers.5")}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label={t("quick_setting.nudges.meal_reminder.title")}>
          <Space size="large">
            <Space>
              <div className="d-flex align-items-center">
                <h4>{t("quick_setting.nudges.meal_reminder.breakfast")}</h4>
                <AppTimePicker
                  className="mx-3"
                  format={format}
                  minuteStep={15}
                />
              </div>
            </Space>

            <Space>
              <div className="d-flex align-items-center">
                <h4>{t("quick_setting.nudges.meal_reminder.snacks")}</h4>
                <AppTimePicker
                  className="mx-3"
                  format={format}
                  minuteStep={15}
                />
              </div>
            </Space>

            <Space>
              <div className="d-flex align-items-center">
                <h4>{t("quick_setting.nudges.meal_reminder.lunch")}</h4>
                <AppTimePicker
                  className="mx-3"
                  format={format}
                  minuteStep={15}
                />
              </div>
            </Space>
          </Space>
        </Form.Item>

        <Form.Item label={t("quick_setting.nudges.drink_water_reminder.title")}>
          <div className="d-flex px-2">
            <h4>{t("quick_setting.nudges.drink_water_reminder.every")}</h4>
            <Radio.Group className="px-4">
              <Space direction="vertical">
                <Radio value={15}>
                  {t("quick_setting.nudges.drink_water_reminder.15_mins")}
                </Radio>
                <Radio value={30}>
                  {t("quick_setting.nudges.drink_water_reminder.30_mins")}
                </Radio>
                <Radio value={45}>
                  {t("quick_setting.nudges.drink_water_reminder.45_mins")}
                </Radio>
                <Radio value={60}>
                  {t("quick_setting.nudges.drink_water_reminder.60_mins")}
                </Radio>
              </Space>
            </Radio.Group>
          </div>
        </Form.Item>

        <AppButton size="large" type="primary">
          {t("quick_setting.modal.save_settings")}
        </AppButton>
      </Form>
    </>
  );
};
