import { styled } from "styled-components";
import { useState } from "react";
import { Space, Tooltip } from "antd";
import { Minus, Plus } from "@phosphor-icons/react";
import { PlStatComponent } from "./pl-stat";
import { TradeStatComponent } from "./trade-stat";
import { FundsComponent } from "./funds";
import { AppButton, HeaderTitle, SectionLayout } from "components";
import { useTranslation } from "react-i18next";

const OverviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    text-align: left !important;
    width: auto !important;
  }
  width: 100%;
  margin: 0 0.5rem;
`;

const OverviewButton = styled(AppButton)`
  color: ${({ color }) => (color ? color : "black")} !important;
  &.active {
    border-bottom: 2px solid white;
  }
`;

export const Overview = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [activeButton, setActiveButton] = useState("pl");
  const { t } = useTranslation();

  const handleButtonClick = (buttonName: string) => {
    setIsExpanded(true);
    setActiveButton(buttonName);
  };

  const expand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <SectionLayout
      header={
        <OverviewHeader>
          <HeaderTitle className="text-center me-2">
            {t("overview.overview")}
          </HeaderTitle>

          <Space>
            <OverviewButton
              color="white"
              type="text"
              className={activeButton === "pl" ? "active" : ""}
              onClick={() => handleButtonClick("pl")}
            >
              {t("overview.pnl")}
            </OverviewButton>
            <OverviewButton
              color="white"
              type="text"
              className={activeButton === "trade" ? "active" : ""}
              onClick={() => handleButtonClick("trade")}
            >
              {t("overview.trade")}
            </OverviewButton>
            <OverviewButton
              color="white"
              type="text"
              className={activeButton === "funds" ? "active" : ""}
              onClick={() => handleButtonClick("funds")}
            >
              {t("overview.funds")}
            </OverviewButton>
          </Space>

          <Space>
            <Tooltip
              title={isExpanded ? t("common.collapse") : t("common.expand")}
            >
              <AppButton
                onClick={expand}
                size="small"
                type="primary"
                shape="circle"
                icon={isExpanded ? <Minus /> : <Plus />}
              />
            </Tooltip>
          </Space>
        </OverviewHeader>
      }
      content={
        isExpanded ? (
          <>
            {activeButton === "pl" && <PlStatComponent />}
            {activeButton === "trade" && <TradeStatComponent />}
            {activeButton === "funds" && <FundsComponent />}
          </>
        ) : null
      }
    />
  );
};
