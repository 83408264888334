import React from "react";
import { useContext } from "react";
import { Navigate, useLocation, Outlet, RouteProps } from "react-router-dom";
import { UserContext } from "contexts/user-context";
import { Layout } from "layout";

const PrivateRoute: React.FC<RouteProps> = ({ element, ...rest }) => {
  const {
    user: { token },
  } = useContext(UserContext);

  const location = useLocation();

  if (!token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return element ? (
    <>{element}</>
  ) : (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default PrivateRoute;
