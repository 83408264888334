import { AppButton, AppInput, Counter } from "components";
import { AvailablCapital } from "./available-capital";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

export const CapitalLevel = () => {
  const handleCounterChange = (label: string, value: number) => {
    console.log(`${label}: ${value}`);
  };
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <>
      <AvailablCapital />
      <Counter
        label={t("risk_manage_system.capital_model.capital_usage_percent")}
        step={1}
        min={1}
        max={100}
        onValueChange={handleCounterChange}
      />

      <Form form={form} layout="vertical">
        <Form.Item
          label={t("risk_manage_system.capital_model.fixed_capital_usage")}
        >
          <AppInput type="number" placeholder="" />
        </Form.Item>
      </Form>

      <AppButton size="large" type="primary">
        {t("quick_setting.modal.save_settings")}
      </AppButton>
    </>
  );
};
