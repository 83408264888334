import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
`;

export const MainContent = styled.div`
  flex: 0 0 calc(70% - 4px);
  @media only screen and (max-width: 1280px) {
    flex: 0 0 calc(65% - 4px);
  }
`;

export const RightBar = styled.div`
  flex: 0 0 calc(30% - 4px);
  @media only screen and (max-width: 1280px) {
    flex: 0 0 calc(35% - 4px);
  }
`;

export const StatSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;
