// util.ts
import { message, notification } from 'antd';

// Loader
let isLoading = false;

export const showLoader = () => {
  isLoading = true;
  // Show loader logic here (e.g., overlay, spinner, etc.)
};

export const hideLoader = () => {
  isLoading = false;
  // Hide loader logic here
};

export const isLoadingVisible = () => {
  return isLoading;
};

// Notifications
export const showSuccessNotification = (message: string, description?: string) => {
  notification.success({
    message,
    description,
  });
};

export const showInfoNotification = (message: string, description?: string) => {
  notification.info({
    message,
    description,
  });
};

export const showWarningNotification = (message: string, description?: string) => {
  notification.warning({
    message,
    description,
  });
};

export const showErrorNotification = (message: string, description?: string) => {
  notification.error({
    message,
    description,
  });
};

// Alert messages
export const showAlertMessage = (msg: string, duration: number = 3) => {
  message.info(msg, duration);
};
