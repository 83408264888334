import { Item, ItemWrapper } from "components";
import { useTranslation } from "react-i18next";

export const FundsComponent = () => {
  const { t } = useTranslation();

  return (
    <ItemWrapper>
      <Item>
        {t("overview.funds_component.funds_available")}
        <span>10</span>
      </Item>
      <Item>
        {t("overview.funds_component.option_premium")}
        <span>5</span>
      </Item>
      <Item>
        {t("overview.funds_component.opening_balance")}
        <span>50</span>
      </Item>
      <Item>
        {t("overview.funds_component.pay_in")}
        <span>5</span>
      </Item>
      <Item>
        {t("overview.funds_component.margin_used")}
        <span>5</span>
      </Item>
      <Item>
        {t("overview.funds_component.pay_out")}
        <span>5</span>
      </Item>
    </ItemWrapper>
  );
};
