import { Form, Radio, Space } from "antd";
import { AppButton, AppInput } from "components";
import { useTranslation } from "react-i18next";

export const DefaultSettings = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <>
      <h2 className="mb-3">
        {t("quick_setting.default_settings.trader_terminal.title")}
      </h2>
      <Form form={form} layout="vertical">
        <Form.Item>
          <Radio.Group>
            <Radio value={1}>
              {t("quick_setting.default_settings.trader_terminal.equity")}
            </Radio>
            <Radio value={2}>
              {t("quick_setting.default_settings.trader_terminal.futures")}
            </Radio>
            <Radio value={3}>
              {t(
                "quick_setting.default_settings.trader_terminal.options_strategy"
              )}
            </Radio>
            <Radio value={4}>
              {t(
                "quick_setting.default_settings.trader_terminal.options_directional"
              )}
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label={t("quick_setting.default_settings.positions.title")}>
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={1}>
                {t("quick_setting.default_settings.positions.todays_trades")}
              </Radio>
              <Radio value={2}>
                {t(
                  "quick_setting.default_settings.positions.todays_fo_positions_only"
                )}
              </Radio>
              <Radio value={3}>
                {t(
                  "quick_setting.default_settings.positions.todays_equity_positions_only"
                )}
              </Radio>
              <Radio value={4}>
                {t(
                  "quick_setting.default_settings.positions.all_fo_positions_only"
                )}
              </Radio>
              <Radio value={5}>
                {t(
                  "quick_setting.default_settings.positions.all_equity_positions_only"
                )}
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Item label={t("quick_setting.default_settings.exchange.title")}>
          <Radio.Group>
            <Radio value={1}>
              {t("quick_setting.default_settings.exchange.bse")}
            </Radio>
            <Radio value={2}>
              {t("quick_setting.default_settings.exchange.nse")}
            </Radio>
            <Radio value={3}>
              {t("quick_setting.default_settings.exchange.mcx")}
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label={t("quick_setting.default_settings.segment.title")}>
          <Radio.Group>
            <Radio value={1}>
              {t("quick_setting.default_settings.segment.equity")}
            </Radio>
            <Radio value={2}>
              {t("quick_setting.default_settings.segment.futures")}
            </Radio>
            <Radio value={3}>
              {t("quick_setting.default_settings.segment.options")}
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label={t("quick_setting.default_settings.order_type.title")}>
          <Radio.Group>
            <Radio value={1}>
              {t("quick_setting.default_settings.order_type.market")}
            </Radio>
            <Radio value={2}>
              {t("quick_setting.default_settings.order_type.ltp")}
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form form={form} layout="vertical">
          <Space size="large">
            <Space>
              <Form.Item
                label={t("quick_setting.default_settings.take_profit.title")}
              >
                <AppInput type="number" placeholder="" />
              </Form.Item>
            </Space>

            <Space>
              <Form.Item
                label={t("quick_setting.default_settings.stop_loss.title")}
              >
                <AppInput type="number" placeholder="" />
              </Form.Item>
            </Space>
          </Space>
        </Form>

        <Form form={form} layout="vertical">
          <Space size="large">
            <Space>
              <Form.Item
                label={t("quick_setting.default_settings.day_target.title")}
              >
                <AppInput type="number" placeholder="" />
              </Form.Item>
            </Space>

            <Space>
              <Form.Item
                label={t("quick_setting.default_settings.day_loss.title")}
              >
                <AppInput type="number" placeholder="" />
              </Form.Item>
            </Space>
          </Space>
        </Form>

        <Form.Item label={t("quick_setting.default_settings.product.title")}>
          <Radio.Group>
            <Radio value={1}>
              {t("quick_setting.default_settings.product.intraday")}
            </Radio>
            <Radio value={2}>
              {t("quick_setting.default_settings.product.margin")}
            </Radio>
          </Radio.Group>
        </Form.Item>

        <AppButton size="large" type="primary">
          {t("quick_setting.modal.save_settings")}
        </AppButton>
      </Form>
    </>
  );
};
