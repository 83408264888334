import { Divider, Form, Typography } from "antd";
import {
  AppButton,
  AppInput,
  AuthBottomLink,
  FormLayouts,
  Wrapper,
} from "components/common";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "utilities/services/api";
import { USER } from "utilities";
import { IForgotPassword } from "models";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";

const schema = yup.object().shape({
  email: yup.string().required("Email is required"),
});

const { Title } = Typography;

export const ForgotPasswordScreen = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPassword>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IForgotPassword) => {
    console.log(data);
    setLoading(true);
    try {
      const response = await api.post(USER.FORGOT_PASSWORD, data);
      console.log(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <FormLayouts>
        <Title>{t("login_page.welcome")}</Title>
        <Divider />
        <Form
          name="wrap"
          labelCol={{ flex: "150px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          style={{ maxWidth: 600 }}
          onFinish={handleSubmit(onSubmit)}
        >
          <Form.Item
            label={t("forgot_password_page.email")}
            name="email"
            validateStatus={errors.email ? "error" : ""}
            help={errors.email && errors.email.message}
          >
            <Controller
              control={control}
              name="email"
              render={({ field }) => <AppInput {...field} />}
            />
          </Form.Item>

          <Form.Item>
            <AppButton type="primary" htmlType="submit" loading={loading}>
              {t("forgot_password_page.forgot_password")}
            </AppButton>
          </Form.Item>

          <AuthBottomLink className="text-center">
            {t("forgot_password_page.back_to")}
            <Link to="/"> {t("login_page.login")} </Link>
          </AuthBottomLink>
        </Form>
      </FormLayouts>
    </Wrapper>
  );
};
