import { styled } from "styled-components";
import { Dropdown } from "antd";
import { useState } from "react";
import { BasketModal } from "./component/basket";
import useTranslatedItems, {
  BlueButton,
  Buy,
  BuySell,
  Content,
  Form,
  FormItem,
  Header,
  HeaderButtons,
  HeaderDropdown,
  HedgeSection,
  MarketToggle,
  PercentageButtonsBuy,
  PercentageButtonsSell,
  Sell,
  SlTargetForm,
  Spacer,
  StyledAppButton,
  StyledAppInput,
  StyledButtonProps,
} from "./component/common";
import OptionChain from "./component/option-chain-chart";
import { showErrorNotification, showSuccessNotification } from "utilities";
import {
  AppButton,
  ConfirmationDialog,
  HeaderTitle,
  SectionLayout,
} from "components";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const HorizontalFormBuy = () => {
  const onFinish = (values: any) => {
    console.log("Received values:", values);
  };

  return (
    <Form layout="inline" onFinish={onFinish}>
      <FormItem name="quantity" rules={[{ max: 5 }]}>
        <StyledAppInput
          type="number"
          placeholder={t("terminal.common_options.placeholder.qty")}
          maxLength={5}
        />
      </FormItem>

      <FormItem name="price" rules={[{ max: 6 }]}>
        <StyledAppInput
          type="number"
          placeholder={t("terminal.common_options.placeholder.price")}
          maxLength={6}
        />
      </FormItem>
      <StyledAppButton type="primary" htmlType="submit" className="green">
        {t("terminal.common_options.placeholder.buy")}
      </StyledAppButton>
    </Form>
  );
};

const HorizontalFormSell = () => {
  const onFinish = (values: any) => {
    console.log("Received values:", values);
  };

  return (
    <Form layout="inline" onFinish={onFinish}>
      <FormItem name="quantity" rules={[{ max: 5 }]}>
        <StyledAppInput
          type="number"
          placeholder={t("terminal.common_options.placeholder.qty")}
          maxLength={5}
        />
      </FormItem>

      <FormItem name="price" rules={[{ max: 6 }]}>
        <StyledAppInput
          type="number"
          placeholder={t("terminal.common_options.placeholder.price")}
          maxLength={6}
        />
      </FormItem>
      <StyledAppButton type="primary" htmlType="submit" className="red">
        {t("terminal.common_options.placeholder.sell")}
      </StyledAppButton>
    </Form>
  );
};

const BuySellFooterAction = styled.div`
  display: flex;
  gap: 8px;
  button {
    flex: 1;
  }
`;

export const EquityFuturesTerminal = () => {
  const { t } = useTranslation();
  const [isBasketModalOpen, setIsBasketModalOpen] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleOk = () => {
    showErrorNotification("Error message demo");
    setVisible(false);
  };

  const handleCancel = () => {
    showSuccessNotification("Success message demo");
    setVisible(false);
  };

  const showConfirmation = () => {
    setVisible(true);
  };

  const showBasketModal = () => {
    setIsBasketModalOpen(true);
  };

  const hideBasketModal = () => {
    setIsBasketModalOpen(false);
  };

  const {
    exchangeItems,
    segmentItems,
    expiryItems,
    lotItems,
    productItems,
    symbolItems,
    typeItems,
    validityItems,
  } = useTranslatedItems();

  return (
    <>
      <SectionLayout
        header={null}
        content={
          <Content className="px-2">
            <Header>
              <div className="d-flex flex-column justify-content-center align-items-center flex-1 gap-3">
                <Dropdown
                  menu={{ items: exchangeItems }}
                  placement="bottomLeft"
                >
                  <HeaderDropdown>
                    {t("terminal.common_options.exchange_options.exchange")}
                  </HeaderDropdown>
                </Dropdown>

                <Dropdown menu={{ items: segmentItems }} placement="bottomLeft">
                  <HeaderDropdown>
                    {t("terminal.common_options.segment_options.segment")}
                  </HeaderDropdown>
                </Dropdown>
              </div>

              <div className="flex-1 d-flex flex-column align-items-center justify-content-center">
                <HeaderTitle className="text-center">
                  BANK NIFTY feb Future
                </HeaderTitle>
                <HeaderTitle className="text-center">LTP: 48,934</HeaderTitle>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center flex-1 gap-3">
                <OptionChain />
                <BasketModal />
              </div>
            </Header>

            <HeaderButtons>
              <Dropdown menu={{ items: symbolItems }} placement="bottomLeft">
                <AppButton>
                  {t("terminal.common_options.symbol_options.symbol")}
                </AppButton>
              </Dropdown>

              <Dropdown menu={{ items: expiryItems }} placement="bottomLeft">
                <AppButton>
                  {t("terminal.common_options.expiry_options.expiry")}
                </AppButton>
              </Dropdown>

              <Dropdown menu={{ items: lotItems }} placement="bottomLeft">
                <AppButton>
                  {t("terminal.common_options.lots_options.lots")}
                </AppButton>
              </Dropdown>

              <Dropdown menu={{ items: productItems }} placement="bottomLeft">
                <AppButton>
                  {t("terminal.common_options.product_options.product")}
                </AppButton>
              </Dropdown>

              <Dropdown menu={{ items: typeItems }} placement="bottomLeft">
                <AppButton>
                  {t("terminal.common_options.type_options.type")}
                </AppButton>
              </Dropdown>

              <Dropdown menu={{ items: validityItems }} placement="bottomLeft">
                <AppButton>
                  {t("terminal.common_options.validity_options.validity")}
                </AppButton>
              </Dropdown>
            </HeaderButtons>

            <div className="d-flex justify-content-end mt-2">
              <MarketToggle />
            </div>

            <BuySell>
              <div className="buy">
                <Buy size="large">{t("terminal.common_options.buy")}</Buy>
                <Spacer>
                  <PercentageButtonsBuy />
                </Spacer>

                <Spacer className="w-100">
                  <HorizontalFormBuy />
                </Spacer>
              </div>

              <div className="sell">
                <Sell size="large">{t("terminal.common_options.sell")}</Sell>

                <Spacer>
                  <PercentageButtonsSell />
                </Spacer>
                <Spacer>
                  <HorizontalFormSell />
                </Spacer>
              </div>
            </BuySell>

            <Spacer>
              <SlTargetForm />
            </Spacer>

            <Spacer>
              <BuySellFooterAction>
                <BlueButton>
                  {t("terminal.common_options.blue_button.reverse")}
                </BlueButton>
                <BlueButton>
                  {t("terminal.common_options.blue_button.exit")}
                </BlueButton>
                <BlueButton onClick={showConfirmation}>
                  {t("terminal.common_options.blue_button.cancel_all")}
                </BlueButton>
              </BuySellFooterAction>
            </Spacer>
            <HedgeSection />
          </Content>
        }
      />

      <ConfirmationDialog
        title={t("terminal.common_options.confirmation.title")}
        content={t("terminal.common_options.confirmation.message")}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </>
  );
};
