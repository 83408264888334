export const lightTheme = {
  primary: '#222199',
  background: '#e3e2e3',
  white: '#ffffff',
  black: '#000000',
  quickSetting: {
    button: "#E1E4FF",
    notch: "#5b5e81",
    border: '#5b5e81',
    hover: {
      background: "#222199",
      color: "#ffffff"
    }
  },
  item: {
    key: '#89898f',
    value: '#080808'
  },
  card: {
    background: "#ffffff"
  },
  borderRadius: '0rem',
  green: '#66FF66',
  red: '#FF6666',
  lightGreen: '#b2fab4',
  lightRed: '#fcbaba',
  darkGreen: '#006400',
  darkRed: '#8B0000',
  border: '#e3e3e3',
  blue: '#0042f1b0'
}

export const darkTheme = {
  primary: '#222199',
  background: 'black',
  white: '#ffffff',
  black: '#000000',
  quickSetting: {
    button: "#E1E4FF",
    notch: "#5b5e81",
    border: '#5b5e81',
    hover: {
      background: "#222199",
      color: "#ffffff"
    }
  },
  item: {
    key: '#89898f',
    value: '#080808'
  },
  card: {
    background: "#ffffff"
  },
  borderRadius: '0rem',
  green: '#66FF66',
  red: '#FF6666',
  lightGreen: '#b2fab4',
  lightRed: '#fcbaba',
  darkGreen: '#006400',
  darkRed: '#8B0000',
  border: '#e3e3e3',
  blue: '#0042f1b0'
}
