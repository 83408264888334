import { TabsProps } from "antd";
import { useState } from "react";
import { CapitalLevel } from "./risk-management/capital-level";
import { SegmentLevel } from "./risk-management/segment-level";
import { TradeLevel } from "./risk-management/trade-level";
import { AdditionalRiskParameter } from "./risk-management/additional-risk-parameter";
import { styled } from "styled-components";
import { AppModal, CustomTabs, QuickSettingItem } from "components";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const RiskAppModal = styled(AppModal)`
  min-height: 700px !important;
  .ant-modal-body {
    height: 700px;
    overflow: scroll;
  }
`;

const PanelPreview = styled.div`
  border-top: 0.0625rem solid ${({ theme }) => theme.border};
  padding-top: 0.5rem;
  display: flex;
  .tabs-preview {
    flex: 1;
    border-right: 0.0625rem solid ${({ theme }) => theme.border};
    &:first-child {
      padding-right: 0.5rem;
    }
    &:last-child {
      padding-left: 0.5rem;
      border-right: 0rem;
    }
  }
`;

export const RiskManagement = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  const items: TabsProps["items"] = [
    {
      key: "capital-level",
      label: t("risk_manage_system.levels.capital_level"),
      children: <CapitalLevel />,
    },
    {
      key: "segment-level",
      label: t("risk_manage_system.levels.segment_level"),
      children: <SegmentLevel />,
    },
    {
      key: "trade-level",
      label: t("risk_manage_system.levels.trade_level"),
      children: <TradeLevel />,
    },
    {
      key: "additional-risk-parameter",
      label: t("risk_manage_system.levels.additional_risk_parameter"),
      children: <AdditionalRiskParameter />,
    },
  ];

  return (
    <>
      <QuickSettingItem
        type="button"
        buttonText={t("risk_manage_system.risk_management_placeholder")}
        onClick={() => setIsVisible(true)}
      />

      <RiskAppModal
        title={t("risk_manage_system.title")}
        open={isVisible}
        onOk={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
        width={1280}
        footer={null}
      >
        <PanelPreview>
          <div className="tabs-preview">
            <CustomTabs
              tabPosition="left"
              type="card"
              defaultActiveKey="1"
              items={items}
            />
          </div>

          <div className="tabs-preview">
            <h1>{t("risk_manage_system.preview")}</h1>
          </div>
        </PanelPreview>
      </RiskAppModal>
    </>
  );
};
