import { AppButton, AppModal } from "components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";

const CustomButton = styled(AppButton)`
  min-width: 120px;
`;

export const Payoff = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <CustomButton onClick={() => setIsVisible(true)}>
        {t("terminal.common_options.payoff")}
      </CustomButton>

      <AppModal
        title={t("terminal.common_options.payoff")}
        open={isVisible}
        onOk={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </AppModal>
    </>
  );
};
