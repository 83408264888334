import { Checkbox, Col, Form, Radio, Row, SelectProps, Space } from "antd";
import { DefaultOptionType } from "antd/es/select";
import {
  AppButton,
  AppCheckBoxGroup,
  AppInput,
  AppSelect,
  AppTimePicker,
} from "components";
import { useTranslation } from "react-i18next";

export const AdditionalRiskParameter = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const options: SelectProps["options"] = [
    {
      value: 1,
      label: t("common.numbers.1"),
    },
    {
      value: 2,
      label: t("common.numbers.2"),
    },
    {
      value: 3,
      label: t("common.numbers.3"),
    },
    {
      value: 4,
      label: t("common.numbers.4"),
    },
    {
      value: 5,
      label: t("common.numbers.5"),
    },
  ];

  const filterOption = (inputValue: string, option?: DefaultOptionType) => {
    const label = option?.label as string | undefined;

    if (label) {
      return label.toLowerCase().includes(inputValue.toLowerCase());
    }
    return false;
  };

  return (
    <>
      <Form form={form} layout="vertical">
        <Form.Item
          label={t("risk_manage_system.add_risk_model.max_trades_per_day")}
        >
          <AppInput type="number" placeholder="" />
        </Form.Item>

        <Form.Item
          label={t("risk_manage_system.add_risk_model.max_freqe_trades.title")}
        >
          <div className="d-flex align-items-start gap-2">
            <AppInput type="number" placeholder="" />
            <AppSelect options={options} />

            <Radio.Group>
              <Space direction="vertical">
                <Radio value={"min"}>
                  {t(
                    "risk_manage_system.add_risk_model.max_freqe_trades.minutes"
                  )}
                </Radio>
                <Radio value={"hour"}>
                  {t("risk_manage_system.add_risk_model.max_freqe_trades.hour")}
                </Radio>
              </Space>
            </Radio.Group>
          </div>
        </Form.Item>

        <Form.Item
          label={t(
            "risk_manage_system.add_risk_model.terminal_specific_days.title"
          )}
        >
          <AppCheckBoxGroup>
            <Checkbox value="mon">
              {t(
                "risk_manage_system.add_risk_model.terminal_specific_days.mon"
              )}
            </Checkbox>
            <Checkbox value="tue">
              {t(
                "risk_manage_system.add_risk_model.terminal_specific_days.tue"
              )}
            </Checkbox>
            <Checkbox value="wed">
              {t(
                "risk_manage_system.add_risk_model.terminal_specific_days.wed"
              )}
            </Checkbox>
            <Checkbox value="thu">
              {t(
                "risk_manage_system.add_risk_model.terminal_specific_days.thu"
              )}
            </Checkbox>
            <Checkbox value="fri">
              {t(
                "risk_manage_system.add_risk_model.terminal_specific_days.fri"
              )}
            </Checkbox>
          </AppCheckBoxGroup>
        </Form.Item>

        <h4 className="mb-2">
          {t("risk_manage_system.add_risk_model.terminal_specific_hours.title")}
        </h4>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={t(
                "risk_manage_system.add_risk_model.terminal_specific_hours.from_open"
              )}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={15}>
                    {t(
                      "risk_manage_system.add_risk_model.terminal_specific_hours.15_min"
                    )}
                  </Radio>
                  <Radio value={30}>
                    {t(
                      "risk_manage_system.add_risk_model.terminal_specific_hours.30_min"
                    )}
                  </Radio>
                  <Radio value={45}>
                    {t(
                      "risk_manage_system.add_risk_model.terminal_specific_hours.45_min"
                    )}
                  </Radio>
                  <Radio value={60}>
                    {t(
                      "risk_manage_system.add_risk_model.terminal_specific_hours.1_hour"
                    )}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t(
                "risk_manage_system.add_risk_model.terminal_specific_hours.from_close"
              )}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={15}>
                    {t(
                      "risk_manage_system.add_risk_model.terminal_specific_hours.15_min"
                    )}
                  </Radio>
                  <Radio value={30}>
                    {t(
                      "risk_manage_system.add_risk_model.terminal_specific_hours.30_min"
                    )}
                  </Radio>
                  <Radio value={45}>
                    {t(
                      "risk_manage_system.add_risk_model.terminal_specific_hours.45_min"
                    )}
                  </Radio>
                  <Radio value={60}>
                    {t(
                      "risk_manage_system.add_risk_model.terminal_specific_hours.1_hour"
                    )}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={t("risk_manage_system.add_risk_model.custom_hour_range.title")}
        >
          <Space>
            <AppTimePicker
              placeholder={t(
                "risk_manage_system.add_risk_model.custom_hour_range.from_time"
              )}
              format="h:mm a"
              minuteStep={15}
            />
            <AppTimePicker
              placeholder={t(
                "risk_manage_system.add_risk_model.custom_hour_range.to_time"
              )}
              format="h:mm a"
              minuteStep={15}
            />
          </Space>
        </Form.Item>

        <Form.Item
          label={t(
            "risk_manage_system.add_risk_model.max_rule_violations.title"
          )}
        >
          <Radio.Group>
            <Radio value={1}>{t("common.numbers.1")}</Radio>
            <Radio value={2}>{t("common.numbers.2")}</Radio>
            <Radio value={3}>{t("common.numbers.3")}</Radio>
            <Radio value={4}>{t("common.numbers.4")}</Radio>
            <Radio value={5}>{t("common.numbers.5")}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={t("risk_manage_system.add_risk_model.symbols_excluded.title")}
        >
          <AppSelect
            showSearch
            placeholder={t(
              "risk_manage_system.add_risk_model.symbols_excluded.placeholder"
            )}
            optionFilterProp="children"
            filterOption={filterOption}
            options={[
              {
                value: "jack",
                label: t(
                  "risk_manage_system.add_risk_model.symbols_excluded.jack"
                ),
              },
              {
                value: "lucy",
                label: t(
                  "risk_manage_system.add_risk_model.symbols_excluded.lucy"
                ),
              },
              {
                value: "tom",
                label: t(
                  "risk_manage_system.add_risk_model.symbols_excluded.tom"
                ),
              },
            ]}
          />
        </Form.Item>
        <AppButton size="large" type="primary">
          {t("quick_setting.modal.save_settings")}
        </AppButton>
      </Form>
    </>
  );
};
