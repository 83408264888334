import { ReactNode } from "react";
import { styled } from "styled-components";
import { HeaderTitle } from "./common";

interface SectionProps {
  header: ReactNode;
  content: ReactNode;
}

const SectionWrapper = styled.div`
  margin-top: 0.5rem;
`;

const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.primary} !important;
  height: 2.625rem;
  display: flex;
  width: 100%;
`;

const SectionContent = styled.div`
  background-color: ${({ theme }) => theme.card.background};
`;

export const SectionLayout: React.FC<SectionProps> = ({ header, content }) => {
  return (
    <SectionWrapper>
      {header && <SectionHeader>{header}</SectionHeader>}
      <SectionContent>{content}</SectionContent>
    </SectionWrapper>
  );
};

export const SectionHeaderTitle = styled(HeaderTitle)`
  width: 100%;
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
`;
