import { ColorPicker, Form, Radio, Space } from "antd";
import { AppButton } from "components";
import { useTranslation } from "react-i18next";

export const Appearance = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <>
      <h2 className="mb-3">{t("quick_setting.appearance.title")}</h2>
      <Form form={form} layout="vertical">
        <Form.Item label={t("quick_setting.appearance.display.title")}>
          <Radio.Group>
            <Radio value={1}>
              {t("quick_setting.appearance.display.light_mode")}
            </Radio>
            <Radio value={2}>
              {t("quick_setting.appearance.display.dark_mode")}
            </Radio>
            <Radio value={3}>
              {t("quick_setting.appearance.display.system")}
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label={t("quick_setting.appearance.font.title")}>
          <Radio.Group>
            <Radio value={1}>{t("quick_setting.appearance.font.roboto")}</Radio>
            <Radio value={2}>
              {t("quick_setting.appearance.font.open_sans")}
            </Radio>
            <Radio value={3}>
              {t("quick_setting.appearance.font.times_new_roman")}
            </Radio>
            <Radio value={4}>{t("quick_setting.appearance.font.arial")}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label={t("quick_setting.appearance.language.title")}>
          <Radio.Group>
            <Radio value={1}>
              {t("quick_setting.appearance.language.english")}
            </Radio>
            <Radio value={2}>
              {t("quick_setting.appearance.language.hindi")}
            </Radio>
            <Radio value={3}>
              {t("quick_setting.appearance.language.kannada")}
            </Radio>
            <Radio value={4}>
              {t("quick_setting.appearance.language.gujarati")}
            </Radio>
            <Radio value={5}>
              {t("quick_setting.appearance.language.telugu")}
            </Radio>
            <Radio value={6}>
              {t("quick_setting.appearance.language.tamil")}
            </Radio>
            <Radio value={7}>
              {t("quick_setting.appearance.language.bengali")}
            </Radio>
            <Radio value={8}>
              {t("quick_setting.appearance.language.marathi")}
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label={t("quick_setting.appearance.button_colors.title")}>
          <Space size="large">
            <Space>
              <h4>{t("quick_setting.appearance.button_colors.buy")}</h4>
              <ColorPicker defaultValue="#1677ff" />
            </Space>

            <Space>
              <h4>{t("quick_setting.appearance.button_colors.sell")}</h4>
              <ColorPicker defaultValue="#1677ff" />
            </Space>

            <Space>
              <h4>{t("quick_setting.appearance.button_colors.exit")}</h4>
              <ColorPicker defaultValue="#1677ff" />
            </Space>

            <Space>
              <h4>{t("quick_setting.appearance.button_colors.cancel")}</h4>
              <ColorPicker defaultValue="#1677ff" />
            </Space>
          </Space>
        </Form.Item>

        <Form.Item
          label={t("quick_setting.appearance.alerts_notifications.title")}
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={1}>
                {t("quick_setting.appearance.alerts_notifications.middle")}
              </Radio>
              <Radio value={2}>
                {t("quick_setting.appearance.alerts_notifications.top_left")}
              </Radio>
              <Radio value={3}>
                {t(
                  "quick_setting.appearance.alerts_notifications.right_bottom"
                )}
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <AppButton size="large" type="primary">
          {t("quick_setting.modal.save_settings")}
        </AppButton>
      </Form>
    </>
  );
};
