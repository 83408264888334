import { FontRegular } from "components";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import { formatToINR } from "utilities";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 0.1rem solid ${({ theme }) => theme.border};
  padding: 0.5rem 0rem;
  margin-bottom: 0.5rem;
`;

const Amount = styled.h1`
  ${FontRegular}
`;

const Label = styled.label`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.item.key};
  ${FontRegular}
`;

export const AvailablCapital = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Amount>{formatToINR(10099.99)}</Amount>
      <Label>{t("risk_manage_system.available_capital.label")}</Label>
    </Wrapper>
  );
};
