import { Dropdown, Space } from "antd";
import useTranslatedMenuItems, {
  Buy,
  BuyCe,
  Cancel,
  Content,
  Header,
  HeaderDropdown,
  HedgeSection,
  Sell,
  SellPe,
} from "./component/common";
import OptionChain from "./component/option-chain-chart";
import { BasketModal } from "./component/basket";
import { styled } from "styled-components";

import { X } from "@phosphor-icons/react";
import { Payoff } from "./component/payoff";
import {
  AppButton,
  AppInput,
  KeyValueDisplay,
  KeyValueWrapper,
  SectionLayout,
} from "components";
import { useTranslation } from "react-i18next";

const Title = styled.h2`
  font-size: 20px;
  text-align: center;
`;

const StrategyForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  div {
    flex: 1;
  }
`;

const FormItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StrategyFormScroll = styled.div`
  max-height: 150px;
  overflow: scroll;
`;

export const OptionsStrategyTerminal = () => {
  const {
    exchangeItems,
    productItems,
    symbolItems,
    typeItems,
    expiryItems,
    validityItems,
    lotMultiplierItems,
  } = useTranslatedMenuItems();

  const { t } = useTranslation();

  return (
    <>
      <SectionLayout
        header={null}
        content={
          <Content className="px-2">
            <Header>
              <div className="d-flex flex-column flex-1 gap-1">
                <Dropdown
                  menu={{ items: exchangeItems }}
                  placement="bottomLeft"
                >
                  <HeaderDropdown>
                    {t("terminal.common_options.exchange_options.exchange")}
                  </HeaderDropdown>
                </Dropdown>

                <Dropdown menu={{ items: productItems }} placement="bottomLeft">
                  <HeaderDropdown>
                    {t("terminal.common_options.product_options.product")}
                  </HeaderDropdown>
                </Dropdown>
              </div>

              <div className="d-flex flex-column flex-1 gap-1">
                <Dropdown menu={{ items: symbolItems }} placement="bottomLeft">
                  <HeaderDropdown>
                    {t("terminal.common_options.symbol_options.symbol")}
                  </HeaderDropdown>
                </Dropdown>

                <Dropdown menu={{ items: typeItems }} placement="bottomLeft">
                  <HeaderDropdown>
                    {t("terminal.common_options.type_options.type")}
                  </HeaderDropdown>
                </Dropdown>
              </div>

              <div className="d-flex flex-column flex-1 gap-1">
                <Dropdown menu={{ items: expiryItems }} placement="bottomLeft">
                  <HeaderDropdown>
                    {t("terminal.common_options.expiry_options.expiry")}
                  </HeaderDropdown>
                </Dropdown>

                <Dropdown
                  menu={{ items: validityItems }}
                  placement="bottomLeft"
                >
                  <HeaderDropdown>
                    {t("terminal.common_options.validity_options.validity")}
                  </HeaderDropdown>
                </Dropdown>
              </div>

              <div className="d-flex flex-column flex-1 gap-1">
                <OptionChain />
                <BasketModal />
              </div>
            </Header>
            <Title>BANK NIFTY</Title>
            <Title>48500</Title>

            <StrategyForm>
              <FormItem>{t("terminal.common_options.expiry")}</FormItem>
              <FormItem>{t("terminal.common_options.strike")}</FormItem>
              <FormItem>{t("terminal.common_options.ce_pe_fut")}</FormItem>
              <FormItem>{t("terminal.common_options.lots")}</FormItem>
              <FormItem>{t("terminal.common_options.price")}</FormItem>
              <FormItem>{t("terminal.common_options.action")}</FormItem>
              <FormItem></FormItem>
            </StrategyForm>

            <StrategyFormScroll>
              {[1, 2, 3, 4, 5, 6].map((i) => (
                <StrategyForm key={i} className="mb-1">
                  <FormItem>
                    <Dropdown
                      menu={{ items: validityItems }}
                      placement="bottomLeft"
                    >
                      <AppButton>22 Feb</AppButton>
                    </Dropdown>
                  </FormItem>

                  <FormItem>
                    <Dropdown
                      menu={{ items: validityItems }}
                      placement="bottomLeft"
                    >
                      <AppButton>22000</AppButton>
                    </Dropdown>
                  </FormItem>

                  <FormItem>
                    <Dropdown
                      menu={{ items: validityItems }}
                      placement="bottomLeft"
                    >
                      <AppButton>CE</AppButton>
                    </Dropdown>
                  </FormItem>

                  <FormItem>
                    <Dropdown
                      menu={{ items: validityItems }}
                      placement="bottomLeft"
                    >
                      <AppButton>10</AppButton>
                    </Dropdown>
                  </FormItem>

                  <FormItem>
                    <AppInput
                      placeholder={t(
                        "terminal.common_options.placeholder.price"
                      )}
                    />
                  </FormItem>

                  <FormItem className="d-flex align-items-center justify-content-center">
                    <BuyCe>B</BuyCe>
                  </FormItem>

                  <FormItem>
                    <SellPe>
                      <X size={16} />
                    </SellPe>
                  </FormItem>
                </StrategyForm>
              ))}
            </StrategyFormScroll>

            <Space className="d-flex justify-content-center my-2">
              <Payoff />

              <Dropdown
                menu={{ items: lotMultiplierItems }}
                placement="bottomLeft"
              >
                <HeaderDropdown>
                  {t("terminal.common_options.lot_multiplier")}
                </HeaderDropdown>
              </Dropdown>
            </Space>

            <div className="d-flex justify-content-center mt-2 gap-2">
              <Buy>{t("terminal.common_options.execute")}</Buy>
              <Sell>{t("terminal.common_options.exit")}</Sell>
              <Cancel>{t("terminal.common_options.cancel")}</Cancel>
            </div>

            <KeyValueWrapper>
              <KeyValueDisplay>
                <span>{t("terminal.common_options.margin_required")}</span>
                <h1>900</h1>
              </KeyValueDisplay>
              <KeyValueDisplay>
                <span>{t("terminal.common_options.max_profit")}</span>
                <h1>900</h1>
              </KeyValueDisplay>
              <KeyValueDisplay>
                <span>{t("terminal.common_options.max_loss")}</span>
                <h1>900</h1>
              </KeyValueDisplay>
            </KeyValueWrapper>

            <HedgeSection />
          </Content>
        }
      />
    </>
  );
};
