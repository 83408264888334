import { TabsProps } from "antd";
import { useState } from "react";
import { Appearance } from "./terminal/appearance";
import { DefaultSettings } from "./terminal/default-settings";
import { Nudges } from "./terminal/nudges";
import { AppModal, CustomTabs, QuickSettingItem } from "components";
import { useTranslation } from "react-i18next";

export const Terminal = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const items: TabsProps["items"] = [
    {
      key: "appearance",
      label: t("quick_setting.modal.levels.appearance"),
      children: <Appearance />,
    },
    {
      key: "default-settings",
      label: t("quick_setting.modal.levels.default_settings"),
      children: <DefaultSettings />,
    },
    {
      key: "nudges",
      label: t("quick_setting.modal.levels.nudges"),
      children: <Nudges />,
    },
  ];

  return (
    <>
      <QuickSettingItem
        type="button"
        buttonText={t("quick_setting.terminal_placeholder")}
        onClick={() => setIsVisible(true)}
      />

      <AppModal
        title={t("quick_setting.modal.title")}
        open={isVisible}
        onOk={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
        width={1280}
        footer={null}
      >
        <div className="tabs-preview">
          <CustomTabs
            tabPosition="left"
            type="card"
            defaultActiveKey="1"
            items={items}
          />
        </div>
      </AppModal>
    </>
  );
};
