import { Col, Divider, Form, Row } from "antd";
import { Typography } from "antd";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IRegistration } from "models";
import { useState } from "react";
import api from "utilities/services/api";
import { USER, showSuccessNotification } from "utilities";
import {
  AppButton,
  AppInput,
  AppPassword,
  AppSelect,
  AppTextArea,
  AuthBottomLink,
  FormLayouts,
  Wrapper,
} from "components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  username: yup
    .string()
    .required("Username is required")
    .min(4, "User Name must be at least 4 characters"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  fname: yup
    .string()
    .required("First name is required")
    .min(4, "First name must be at least 4 characters"),
  lname: yup
    .string()
    .required("Last name is required")
    .min(4, "Last name must be at least 4 characters"),
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(/^[0-9]{10}$/, "Phone number is not valid"),
  email: yup.string().email("Invalid email").required("Email is required"),
  address: yup
    .string()
    .required("Address is required")
    .min(8, "Address must be at least 8 characters"),
  gst: yup.string(),
  gender: yup.string().required("Gender is required"),
});

const { Title } = Typography;
export const RegisterScreen = () => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IRegistration>({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: IRegistration) => {
    setLoading(true);
    try {
      const response = await api.post(USER.SIGNUP, data);
      showSuccessNotification(response.message);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Wrapper>
        <FormLayouts>
          <Title>Welcome</Title>
          <Divider />
          <Form
            name="wrap"
            labelCol={{ flex: "170px" }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
            style={{ maxWidth: 600 }}
            onFinish={handleSubmit(onSubmit)}
          >
            <Row gutter={24}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("register_page.first_name")}
                  name="fname"
                  validateStatus={errors.fname ? "error" : ""}
                  help={errors.fname && errors.fname.message}
                >
                  <Controller
                    control={control}
                    name="fname"
                    render={({ field }) => <AppInput {...field} />}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("register_page.last_name")}
                  name="lname"
                  validateStatus={errors.lname ? "error" : ""}
                  help={errors.lname && errors.lname.message}
                >
                  <Controller
                    control={control}
                    name="lname"
                    render={({ field }) => <AppInput {...field} />}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("register_page.user_name")}
                  name="userName"
                  validateStatus={errors.username ? "error" : ""}
                  help={errors.username && errors.username.message}
                >
                  <Controller
                    control={control}
                    name="username"
                    render={({ field }) => <AppInput {...field} />}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("register_page.password")}
                  name="password"
                  validateStatus={errors.password ? "error" : ""}
                  help={errors.password && errors.password.message}
                >
                  <Controller
                    control={control}
                    name="password"
                    render={({ field }) => <AppInput.Password {...field} />}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("register_page.email")}
                  name="email"
                  validateStatus={errors.email ? "error" : ""}
                  help={errors.email && errors.email.message}
                >
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => <AppInput {...field} />}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("register_page.gender")}
                  name="gender"
                  validateStatus={errors.gender ? "error" : ""}
                  help={errors.gender && errors.gender.message}
                >
                  <Controller
                    control={control}
                    name="gender"
                    render={({ field }) => (
                      <AppSelect
                        {...field}
                        options={[
                          { value: "male", label: "Male" },
                          { value: "female", label: "Female" },
                          { value: "other", label: "Other" },
                        ]}
                        defaultValue=""
                        onChange={(value) => field.onChange(value)}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("register_page.phone")}
                  name="phone"
                  validateStatus={errors.phone ? "error" : ""}
                  help={errors.phone && errors.phone.message}
                >
                  <Controller
                    control={control}
                    name="phone"
                    render={({ field }) => <AppPassword {...field} />}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("register_page.gst")}
                  name="gst"
                  validateStatus={errors.gst ? "error" : ""}
                  help={errors.gst && errors.gst.message}
                >
                  <Controller
                    control={control}
                    name="gst"
                    render={({ field }) => <AppInput {...field} />}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("register_page.address")}
                  name="address"
                  validateStatus={errors.address ? "error" : ""}
                  help={errors.address && errors.address.message}
                >
                  <Controller
                    control={control}
                    name="address"
                    render={({ field }) => <AppTextArea rows={4} {...field} />}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <AppButton type="primary" htmlType="submit" loading={loading}>
                {t("register_page.register")}
              </AppButton>
            </Form.Item>

            <AuthBottomLink className="text-center">
              {t("register_page.already_have_an_account")}
              <Link to="/">{t("login_page.login")}</Link>
            </AuthBottomLink>
          </Form>
        </FormLayouts>
      </Wrapper>
    </>
  );
};
