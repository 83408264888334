const BASE_URL = 'http://74.225.252.111:3000';


export const USER = {
    LOGIN: `${BASE_URL}/api/users/signin`,
    SIGNUP: `${BASE_URL}/api/users/signup`,
    FORGOT_PASSWORD: `${BASE_URL}/api/users/forgot-password`
}

export const BROKERS = {
    LIST: `${BASE_URL}/api/brokers`
}