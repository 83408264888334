import { styled } from "styled-components";

import { Col, Form, Radio, Row, Space, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  Broom,
  FloppyDisk,
  Funnel,
  Gear,
  MagnifyingGlass,
  Minus,
  PencilSimple,
  Plus,
  Trash,
  X,
} from "@phosphor-icons/react";
import { useState } from "react";

import { SaveOutlined } from "@ant-design/icons";
import {
  AppButton,
  AppInput,
  AppModal,
  AppSelect,
  AppTag,
  ContentWrapper,
  HeaderTitle,
  SectionLayout,
  WatchListTable,
} from "components";
import { useTranslation } from "react-i18next";
const { Option } = AppSelect;

const WatchListHeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    text-align: left !important;
    width: auto !important;
  }
  width: 100%;
  margin: 0 0.5rem;
`;

const WatchListInput = styled(AppInput)`
  max-width: 180px;
`;

const BuySellAction = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    margin: 0px;
  }
`;

const initialColumns: ColumnsType = [
  {
    dataIndex: "name",
    key: "name",
  },
  {
    dataIndex: "type",
    key: "type",
  },
  {
    dataIndex: "amount",
    key: "amount",
  },
  {
    dataIndex: "change",
    key: "change",
  },
  {
    dataIndex: "change_percentage",
    key: "change_percentage",
    render: (text) => `(${text}%)`,
  },
  {
    dataIndex: "action",
    key: "action",
    width: 60,
    render: () => (
      <BuySellAction>
        <AppTag color="green">B</AppTag>
        <AppTag color="volcano">S</AppTag>
      </BuySellAction>
    ),
  },
];

const data = [
  {
    key: "1",
    name: "Banknifty 7 Feb 46000 PE",
    type: "NSE",
    amount: 47560,
    change: 600,
    change_percentage: "1.3",
  },
  {
    key: "2",
    name: "Banknifty 7 Feb 46000 PE",
    type: "NSE",
    amount: 47560,
    change: 600,
    change_percentage: "1.3",
  },
  {
    key: "3",
    name: "Banknifty 7 Feb 46000 PE",
    type: "NSE",
    amount: 47560,
    change: 600,
    change_percentage: "1.3",
  },
  {
    key: "4",
    name: "Banknifty 7 Feb 46000 PE",
    type: "NSE",
    amount: 47560,
    change: 600,
    change_percentage: "1.3",
  },
  {
    key: "5",
    name: "Banknifty 7 Feb 46000 PE",
    type: "NSE",
    amount: 47560,
    change: 600,
    change_percentage: "1.3",
  },
  {
    key: "6",
    name: "Banknifty 7 Feb 46000 PE",
    type: "NSE",
    amount: 47560,
    change: 600,
    change_percentage: "1.3",
  },
  {
    key: "7",
    name: "Banknifty 7 Feb 46000 PE",
    type: "NSE",
    amount: 47560,
    change: 600,
    change_percentage: "1.3",
  },
  {
    key: "8",
    name: "Banknifty 7 Feb 46000 PE",
    type: "NSE",
    amount: 47560,
    change: 600,
    change_percentage: "1.3",
  },
  {
    key: "",
    name: "Banknifty 7 Feb 46000 PE",
    type: "NSE",
    amount: 47560,
    change: 600,
    change_percentage: "1.3",
  },
  {
    key: "10",
    name: "Banknifty 7 Feb 46000 PE",
    type: "NSE",
    amount: 47560,
    change: 600,
    change_percentage: "1.3",
  },
];

export const WatchListComponent = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isWatchlistVisible, setIsWatchlistVisible] = useState(false);
  const [columns, setColumns] = useState(initialColumns);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const editWatchList = () => {
    setIsEdit(!isEdit);
    setColumns((prevColumns) => {
      if (!isEdit) {
        return [
          ...prevColumns,
          {
            title: t("watchlist.delete"),
            key: "delete",
            render: (text, record) => (
              <div className="d-flex justify-content-center align-items-center">
                <Trash onClick={() => handleDelete(record.key)} size={16} />
              </div>
            ),
          },
        ];
      } else {
        return prevColumns.filter((col) => col.key !== "delete");
      }
    });
  };

  const handleDelete = (key: any) => {
    console.log(`Delete record with key: ${key}`);
  };

  return (
    <>
      <SectionLayout
        header={
          <WatchListHeaderTitle>
            <HeaderTitle className="text-center me-2">
              {t("watchlist.title")}
            </HeaderTitle>

            <WatchListInput
              className="me-2"
              size="small"
              placeholder={t("watchlist.search_placeholder")}
              prefix={<MagnifyingGlass />}
              suffix={<Funnel />}
            />

            <Space.Compact className="d-flex gap-1">
              <AppButton size="small" shape="circle">
                {t("common.numbers.1")}
              </AppButton>
              <AppButton size="small" shape="circle">
                {t("common.numbers.2")}
              </AppButton>
              <AppButton size="small" shape="circle">
                {t("common.numbers.3")}
              </AppButton>
              <AppButton size="small" shape="circle">
                {t("common.numbers.4")}
              </AppButton>
              <AppButton size="small" shape="circle">
                {t("common.numbers.5")}
              </AppButton>
            </Space.Compact>
            <Space>
              <Tooltip title={t("watchlist.clear_list")}>
                {!isEdit && (
                  <AppButton
                    size="small"
                    type="primary"
                    onClick={editWatchList}
                    icon={<PencilSimple size={16} />}
                  />
                )}

                {isEdit && (
                  <AppButton
                    size="small"
                    type="primary"
                    onClick={editWatchList}
                    icon={<X size={16} />}
                  />
                )}
              </Tooltip>

              <Tooltip title={t("watchlist.setting")}>
                <AppButton
                  size="small"
                  type="primary"
                  shape="circle"
                  onClick={() => setIsWatchlistVisible(true)}
                  icon={<Gear />}
                />
              </Tooltip>

              <Tooltip
                title={isExpanded ? t("common.collapse") : t("common.expand")}
              >
                <AppButton
                  onClick={() => setIsExpanded(!isExpanded)}
                  size="small"
                  type="primary"
                  shape="circle"
                  icon={isExpanded ? <Minus /> : <Plus />}
                />
              </Tooltip>
            </Space>
          </WatchListHeaderTitle>
        }
        content={
          isExpanded ? (
            <ContentWrapper>
              <div className="d-flex justify-content-between gap-2">
                {isEdit && (
                  <div className="mb-2">
                    <AppButton
                      size="small"
                      type="primary"
                      icon={<SaveOutlined />}
                    >
                      {t("watchlist.save")}
                    </AppButton>
                  </div>
                )}
              </div>
              <WatchListTable
                maxHeight={isEdit ? "10rem" : "12rem"}
                columns={columns}
                dataSource={data}
                pagination={false}
                showHeader={false}
              />
            </ContentWrapper>
          ) : null
        }
      />

      <AppModal
        title={t("watchlist.watchlist_settings.title")}
        open={isWatchlistVisible}
        onOk={() => setIsWatchlistVisible(false)}
        onCancel={() => setIsWatchlistVisible(false)}
        footer={null}
      >
        <div className="d-flex flex-column">
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={t(
                    "watchlist.watchlist_settings.default_order_type.title"
                  )}
                >
                  <Radio.Group>
                    <Radio value={"market"}>
                      {t(
                        "watchlist.watchlist_settings.default_order_type.market"
                      )}
                    </Radio>
                    <Radio value={"limit"}>
                      {t(
                        "watchlist.watchlist_settings.default_order_type.limit"
                      )}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t("watchlist.watchlist_settings.default_quantity")}
                >
                  <AppInput
                    type="number"
                    addonAfter={
                      <AppSelect defaultValue="1">
                        {Array.from({ length: 10 }, (_, i) => (
                          <Option key={i + 1} value={i + 1}>
                            {i + 1}
                          </Option>
                        ))}
                      </AppSelect>
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={t("watchlist.watchlist_settings.default_sl")}>
                  <AppInput type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t("watchlist.watchlist_settings.default_target")}
                >
                  <AppInput type="number" />
                </Form.Item>
              </Col>
            </Row>

            <AppButton size="large" type="primary">
              {t("quick_setting.modal.save_settings")}
            </AppButton>
          </Form>
        </div>
      </AppModal>
    </>
  );
};
