import React, { ReactNode } from 'react';
import { HeaderComponent } from './header';

interface LayoutProps {
  children: ReactNode;
}

export function Layout({ children }: LayoutProps) {
  return (
    <>
      <HeaderComponent />
      {children}
    </>
  );
}
