interface LSItem {
    key: string;
    value: string;
  }
  
  export const Storage = {
    getItem(key: string): string | null {
      if (typeof window === 'undefined') {
        // Server-side rendering, localStorage is not available
        return null;
      }
      try {
        const value = localStorage.getItem(key);
        return value !== null ? value : null;
      } catch (error) {
        console.error('Error getting item from local storage:', error);
        return null;
      }
    },
  
    setItem({ key, value }: LSItem): void {
      if (typeof window === 'undefined') {
        // Server-side rendering, localStorage is not available
        return;
      }
      try {
        localStorage.setItem(key, value);
      } catch (error) {
        console.error('Error setting item in local storage:', error);
      }
    },
  
    removeItem(key: string): void {
      if (typeof window === 'undefined') {
        // Server-side rendering, localStorage is not available
        return;
      }
      try {
        localStorage.removeItem(key);
      } catch (error) {
        console.error('Error removing item from local storage:', error);
      }
    }
  };
  