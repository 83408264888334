import { CustomTable } from "components";
import { useTranslation } from "react-i18next";

export const TradeBookTable = () => {
  const { t } = useTranslation();

  const tradeBookColumn = [
    {
      title: t("mtm.orderbook_tradebook_common_fields.symbol"),
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: t("mtm.orderbook_tradebook_common_fields.product"),
      dataIndex: "product",
      key: "product",
    },
    {
      title: t("mtm.orderbook_tradebook_common_fields.quantity"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: t("mtm.orderbook_tradebook_common_fields.avg_price"),
      dataIndex: "avg_price",
      key: "avg_price",
    },
    {
      title: t("mtm.orderbook_tradebook_common_fields.time"),
      dataIndex: "time",
      key: "time",
    },
    {
      title: t("mtm.orderbook_tradebook_common_fields.trade_id"),
      dataIndex: "trade_id",
      key: "trade_id",
    },
    {
      title: t("mtm.orderbook_tradebook_common_fields.log"),
      dataIndex: "log",
      key: "log",
    },
  ];

  const tradeBookData = [
    {
      symbol: "AAPL",
      product: "Equity",
      quantity: 100,
      avg_price: 150.25,
      time: "2024-05-20T09:30:00Z",
      trade_id: "ORD12345",
      log: "Order executed successfully",
    },
    {
      symbol: "TSLA",
      product: "Equity",
      quantity: 50,
      avg_price: 650.75,
      time: "2024-05-20T09:45:00Z",
      trade_id: "ORD12346",
      log: "Order executed successfully",
    },
    {
      symbol: "GOOGL",
      product: "Equity",
      quantity: 30,
      avg_price: 2725.0,
      time: "2024-05-20T10:00:00Z",
      trade_id: "ORD12347",
      log: "Order executed successfully",
    },
    {
      symbol: "AMZN",
      product: "Equity",
      quantity: 20,
      avg_price: 3300.5,
      time: "2024-05-20T10:15:00Z",
      trade_id: "ORD12348",
      log: "Order executed successfully",
    },
    {
      symbol: "MSFT",
      product: "Equity",
      quantity: 75,
      avg_price: 300.4,
      time: "2024-05-20T10:30:00Z",
      trade_id: "ORD12349",
      log: "Order executed successfully",
    },
  ];

  return (
    <CustomTable
      pagination={false}
      columns={tradeBookColumn}
      dataSource={tradeBookData}
    />
  );
};
